const getReglerFactureBaseUrl = () => {
  let baseUrl = document.location.origin.replace('espace-client', 'ow-reglement-facture')
  if (process.env.REACT_APP_REGLER_FACTURE_URL) {
    // DEV
    baseUrl = process.env.REACT_APP_REGLER_FACTURE_URL
  } else if (baseUrl.includes('ow-reglement-facture-pp')) {
    // PREPROD
    baseUrl = 'https://ow-reglement-facture-pp.gagi.fr'
  }

  return baseUrl
}

export const getReglerFactureUrl = (factureNumero, factureCle) => {
  return getReglerFactureBaseUrl() + '/fr/regler-facture/' + factureNumero + '/' + factureCle
}

export const getReglerReleveUrl = (clientCode, releveNumero, userEmail) => {
  return getReglerFactureBaseUrl() + '/regler-releve/' + clientCode + '/' + releveNumero + '/' + userEmail
}

export const getApprovisionnementUrl = (clientCode, userEmail) => {
  return getReglerFactureBaseUrl()
    + '/approvisionnement/' + encodeURIComponent(clientCode)
    + '/' + encodeURIComponent(userEmail)

}

export default getReglerFactureBaseUrl
