import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

import { Link, FilledInput, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, Switch, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/CloseOutlined'
import CheckIcon from '@mui/icons-material/CheckOutlined'
import AddIcon from '@mui/icons-material/AddOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import MailIcon from '@mui/icons-material/MailOutlined'
// import LoginIcon from '@mui/icons-material/LoginOutlined'
import Box from '@mui/material/Box'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import { dateTimeFr } from './lib/date'
import Headband from './components/Headband'
import MyTableRow from './components/TableRow'
import ListItems from './components/ListItems'
import useTheme from './themes/material'
import MyFab from './components/Fab'
import PageContent from './components/PageContent'

const editUrl = '/gestion-des-utilisateurs/edition'

export default function GestionDesUtilisateurs () {
  const app = useContext(AppContext)
  const theme = useTheme()
  const [cookies, setCookie] = useCookies()
  const navigate = useNavigate()

  const [items, setItems] = useState(null)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [seeInactives, setSeeInactives] = useState(cookies['users-seeInactives'] === 'true')
  const [welcomeUser, setWelcomeUser] = useState(null)

  const columns = [
    { code: 'count', label: '#', sort: 'count' },
    { code: 'client_code', label: 'Code client', sort: 'client_code' },
    { code: 'client_name', label: 'Nom client', sort: 'client_name' },
    { code: 'fullNameCell', label: 'Utilisateur', sort: 'fullname' },
    { code: 'emailLink', label: 'Email', sort: 'email' },
    { code: 'activeChip', label: 'État', sort: 'active' },
    { code: 'welcomeDateheure', label: <>Email de bienvenue<br />envoyé le</>, sort: 'ec_welcome_email_dateheure' },
    { code: 'actions', label: '' }
  ]

  const getClientName = clientCode => {
    for (let i = 0, ln = app.clientOptions.length; i < ln; i++) {
      if (clientCode === app.clientOptions[i].id) {
        return app.clientOptions[i].client_label || app.clientOptions[i].label
      }
    }
    return clientCode || ''
  }

  const getUsers = () => {
    setItems(null)
    setPage(0)

    api.get('users').then(response => {
      response.data.map(item => {
        item.fullname = [item.firstname, item.name].join(' ')
        item.client_name = getClientName(item.client_code)
        return item
      })
      setItems(response.data)
    }).catch(error => {
      setItems([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des utilisateurs')
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    getUsers()
  }

  const handleSetSeeInactives = () => {
    setSeeInactives(!seeInactives)
    setPage(0)

    setCookie('users-seeInactives', !seeInactives, {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const sendWelcomeEmail = user => {
    api.post('user-send-welcome-email/' + user.id).then(response => {
      if (typeof response !== 'object' ||
      response === null ||
      response.status !== 200 ||
      response.statusText !== 'OK') {
        throw new Error("L'email de bienvenue n'a pas été envoyé à l'adresse " + user.email + ' ; veuillez réitérer la demande ultérieurement')
      } else if (response?.data?.error) {
        throw new Error(response.data.error)
      }

      setWelcomeUser(null)

      if (response?.data?.ec_welcome_email_dateheure) {
        const itemsWithWelcomeEmail = []
        items.map(item => {
          if (item.id === user.id) {
            item.ec_welcome_email_dateheure = response.data.ec_welcome_email_dateheure
          }
          itemsWithWelcomeEmail.push(item)
          return item
        })
        setItems(itemsWithWelcomeEmail)
      }

      app.snackbarSuccess(response.data.message || "L'email de bienvenue a bien été envoyé à l'adresse " + user.email)
    }).catch((error) => {
      setWelcomeUser(null)
      app.snackbarError(error.message || "Erreur lors de l'envoi de l'email de bienvenue à l'adresse " + user.email)
    })
  }

  useEffect(getUsers, [])

  const sxTextfield = {
    width: '240px'
  }

  const renderTableRow = (item) => {
    const row = { ...item }

    row.data = item

    row.fullNameCell = (
      <>
        {row.fullname}
        {row.admin ? <em> (Admin)</em> : ''}
      </>
    )

    row.emailLink = !row.email
      ? null
      : (
        <Link href={`mailto:${row.email}`} target='_blank' rel='noreferrer'>{row.email}</Link>
        )

    row.activeChip = row.active
      ? (
        <span style={{ display: 'inline-flex', alignItems: 'center', color: theme.palette.success.main }}>
          <CheckIcon color='success' sx={{ marginRight: theme.spacing(1) }} />Actif
        </span>
        )
      : <span style={{ color: theme.palette.error.main }}>Inactif</span>

    row.welcomeDateheure = ''
    let welcomeTooltip = "Envoyer l'email de bienvenue"
    if (row.ec_welcome_email_dateheure && row.ec_welcome_email_dateheure.date) {
      row.welcomeDateheure = dateTimeFr(row.ec_welcome_email_dateheure.date)
      welcomeTooltip = "Renvoyer l'email de bienvenue"
    }

    row.actions = (
      <>
        <Tooltip title={welcomeTooltip}>
          <IconButton onClick={() => { setWelcomeUser(item) }}>
            <MailIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Modifier l'utilisateur">
          <IconButton onClick={() => { navigate(editUrl, { state: item }) }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {/* && app.user.admin && app.user.id !== item.id && (
            <Tooltip title="S'identifier avec cet utilisateur">
              <IconButton onClick={ () => {
                navigate('/')
                app.login(item)
              }}>
                <LoginIcon />
              </IconButton>
            </Tooltip>
          )
        */}
      </>
    )

    return (
      <MyTableRow
        key={row.id}
        row={row}
        columns={columns}
      />
    )
  }

  const filteredItems = items && items.filter(item => {
    if (!seeInactives && !item.active) {
      return false
    }

    if (search.trim() === '') return item
    if (item.firstname && item.firstname.toLowerCase().indexOf(search.toLowerCase()) !== -1) return item
    if (item.name && item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) return item
    if (item.email && item.email.toLowerCase().indexOf(search.toLowerCase()) !== -1) return item
    if (item.client_code && item.client_code.toLowerCase().indexOf(search.toLowerCase()) !== -1) return item
    if (item.client_name && item.client_name.toLowerCase().indexOf(search.toLowerCase()) !== -1) return item
    return false
  })

  return (
    <PageContent>
      <Headband alignLeft>
        <form style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }} onSubmit={event => handleSubmit(event)}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
            <Typography variant='h1' sx={{ display: 'inline-block', margin: '0' }}>Gestion des utilisateurs</Typography>
            {/* <Tooltip title='Ajouter un nouvel utilisateur'>
              <IconButton
                aria-label='Ajouter un nouvel utilisateur'
                sx={{ borderWidth:'2px', borderStyle: 'solid' }}
                onClick={ () => navigate(editUrl) }
              >
                <AddIcon />
              </IconButton>
            </Tooltip> */}
          </Box>
          <Box sx={{ display: 'flex', gap: theme.spacing(4), zzjustifyContent: 'space-between', alignItems: 'center' }}>
            <FormControl sx={sxTextfield} variant='filled'>
              <InputLabel htmlFor='search'>Recherche générale</InputLabel>
              <FilledInput
                id='search'
                value={search}
                onChange={event => {
                  setSearch(event.target.value)
                  setPage(0)
                }}
                endAdornment={search && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Bouton pour vider le champ'
                      onClick={() => {
                        setSearch('')
                        setPage(0)
                        document.getElementById('search').focus()
                      }}
                      edge='end'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <FormControlLabel
              control={<Switch checked={seeInactives} onChange={handleSetSeeInactives} color='secondary' />}
              label='Afficher les inactifs'
              sx={{ color: '#ffffff' }}
            />
          </Box>
        </form>
      </Headband>
      <ListItems
        name='users'
        items={filteredItems}
        page={page}
        setPage={setPage}
        renderTableRow={renderTableRow}
        columns={columns}
      />
      <MyFab
        icon={<AddIcon />}
        label='Ajouter un utilisateur'
        ariaLabel='add'
        onClick={() => navigate(editUrl)}
      />
      <Dialog
        maxWidth='xs'
        open={!!welcomeUser}
      >
        <DialogTitle>Attention</DialogTitle>
        <DialogContent>
          Vous êtes sur le point d'envoyer l'email de bienvenue à l'utilisateur {welcomeUser?.firstname} {welcomeUser?.name} ({welcomeUser?.email}).
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => { setWelcomeUser(null) }}>
            Annuler
          </Button>
          <Button onClick={() => { sendWelcomeEmail(welcomeUser) }}>Envoyer l'email de bienvenue</Button>
        </DialogActions>
      </Dialog>
    </PageContent>
  )
}
