import { useState, useContext } from 'react'
import { Box, FilledInput, FormControl, InputLabel } from '@mui/material'
import api from './../lib/api'
// import ReCAPTCHA from 'react-google-recaptcha'

export default function Captcha ({ uniqId, value, onChange }) {
  const sx = { width: '100%', margin: '.75rem 0' }

  return (
    <FormControl variant='filled' required sx={sx}>
      <InputLabel htmlFor='captcha'>Saisir les caractères</InputLabel>
      <FilledInput
        id='captcha'
        type='text'
        value={value}
        onChange={onChange}
        label='Saisir les caractères'
        sx={{ paddingRight: '160px' }}
      />
      <Box sx={{
        width: '200px',
        height: '53px',
        position: 'absolute',
        right: '0',
        top: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <img src={`${api.getBaseURL()}captcha?v=${uniqId}`} alt='captcha' />
      </Box>
    </FormControl>
  )
}

/*
export default function Captcha ({ reference, onChange, sx }) {
  const sxContainer = Object.assign({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.5rem 0',
    backgroundColor: '#f9f9f9'
  }, sx)

  return (
    <Box sx={sxContainer}>
      <Box sx={{ width: '300px', height: '74px', overflow: 'hidden' }}>
        <ReCAPTCHA
          ref={reference}
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={onChange}
          style={{ margin: '-1px 0 0 -1px' }}
        />
      </Box>
    </Box>
  )
}
*/
