import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs'

import { Chip, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/SearchOutlined'
import DeleteIcon from '@mui/icons-material/CloseOutlined'
import Box from '@mui/material/Box'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import Headband from './components/Headband'
import ListItems from './components/ListItems'
import TableRowRcs from './components/TableRowRcs'
import { ColorModeContext } from './context/ColorModeContext'
import useTheme from './themes/material'
import DatePicker, { dateMinImportant, dateMaxImportant } from './components/DatePicker'
import dateFr from './lib/date'
import { siren } from './lib/format'
import Link from './components/Link'
import LinkSiren from './components/LinkSiren'
import PageContent from './components/PageContent'

export default function Judiciaire () {
  const app = useContext(AppContext)
  const { colorMode } = useContext(ColorModeContext)
  const theme = useTheme(colorMode)
  const [cookies, setCookie] = useCookies()

  const dateMinDefault = cookies['judiciaire-dateMin'] ? dayjs(cookies['judiciaire-dateMin']) : dayjs(process.env.REACT_APP_DATE_NOW).startOf('month')
  const dateMaxDefault = cookies['judiciaire-dateMax'] ? dayjs(cookies['judiciaire-dateMax']) : dayjs(process.env.REACT_APP_DATE_NOW).endOf('month')

  const [items, setItems] = useState(null)
  const [page, setPage] = useState(0)
  const [depotNumero, setDepotNumero] = useState('')
  const [affaireNumero, setAffaireNumero] = useState('')
  const [demandeurDefendeur, setDemandeurDefendeur] = useState('')
  const [dateMin, setDateMin] = useState(dateMinDefault)
  const [dateMax, setDateMax] = useState(dateMaxDefault)
  const [type, setType] = useState('all')
  const [typeOptions, setTypeOptions] = useState([])
  const [submitCount, setSubmitCount] = useState(0)

  const columns = [
    { code: 'count', label: '#', sort: 'count' },
    { code: 'affaire_numero', label: 'N° affaire', sort: 'affaire_numero', sx: { fontWeight: 'bold' } },
    { code: 'enrolement_date', label: 'Date enrôlement', filter: dateFr, sort: 'enrolement_date' },
    // { code: 'depot_chrono', label: 'N° dépôt', sort: 'depot_chrono' },
    // { code: 'depot_date', label: 'Date dépôt', filter: dateFr, sort: 'depot_date' },
    // { code: 'type_label', label: 'Type', sort: 'affaire_type' },
    { code: 'affaire_type', label: 'Type', sort: 'affaire_type' },
    { code: 'affaire_nature', label: 'Nature', sort: 'affaire_nature' },
    { code: 'etat_chip', label: 'État du dossier', sort: 'etat' }
  ]

  const formIsValid = () => {
    return depotNumero || affaireNumero || demandeurDefendeur || (
      (dateMin.isValid() && dateMin >= dateMinImportant) &&
      (dateMax.isValid() && dateMax <= dateMaxImportant)
    )
  }

  const apiGetItems = () => {
    if (!formIsValid()) {
      app.snackbarError('Veuillez vérifier les filtres de recherche')
      return
    }

    setItems(null)
    setPage(0)

    api.post('judiciaire', {
      client_code: app.user.client_code,
      depot_numero: (depotNumero || '').trim(),
      affaire_numero: (affaireNumero || '').trim(),
      demandeur_defendeur: (demandeurDefendeur || '').trim(),
      date_min: (depotNumero || affaireNumero ? dateMinImportant : dateMin).format('DD/MM/YYYY'),
      date_max: (depotNumero || affaireNumero ? dateMaxImportant : dateMax).format('DD/MM/YYYY')
    }).then(response => {
      setItems(response.data)
    }).catch(error => {
      setItems([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des assignations et des requêtes')
    })
  }

  const filteredItems = items === null
    ? null
    : items.filter(item => (
      !type || type === 'all' || type === item.affaire_type ? item : false
    ))

  const handleSetDateMin = (newDate) => {
    setDateMin(newDate)

    setCookie('judiciaire-dateMin', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSetDateMax = (newDate) => {
    setDateMax(newDate)

    setCookie('judiciaire-dateMax', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmitCount(submitCount + 1)
  }

  useEffect(() => {
    if (submitCount > 0) {
      apiGetItems()
    }
  }, [submitCount])

  useEffect(() => {
    setSubmitCount(submitCount + 1)
  }, [])

  useEffect(() => {
    const types = []
    if (items && items.length) {
      items.map(item => {
        if (!types.includes(item.affaire_type)) {
          types.push(item.affaire_type)
        }
        return item
      })
    }
    setTypeOptions(types)
  }, [items])

  const renderIntervenantTr = (label, nom, sirenNumero, gestionNumero) => (
    <tr>
      <td style={{ fontSize: 'smaller' }}>{label}</td>
      <td style={{ verticalAlign: 'top' }}>
        :
        <span style={{
          fontWeight: 'bold',
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1)
        }}
        >
          {nom}
        </span>
        {!!sirenNumero && !!gestionNumero && (
          <>
            (
            <LinkSiren
              sirenNumero={sirenNumero}
              gestionNumero={gestionNumero}
            />
            )
          </>
        )}
        {!!sirenNumero && !gestionNumero && (
          '(' + siren(sirenNumero) + ')'
        )}
      </td>
    </tr>
  )

  const renderTableRow = (item) => {
    const row = { ...item }

    row.demandeur_defendeur = (
      <>
        {row.demandeur_nom}
        <br />
        {row.defendeur_nom}
      </>
    )

    let chipLabel = 'Indéfini'
    let chipColor = 'error'
    if (row.etat === 'assignation') {
      chipLabel = 'Assignation'
      chipColor = 'primary'
    } else if (row.etat === 'requete_attente') {
      chipLabel = 'Requête en attente de régularisation'
      chipColor = 'error'
    } else if (row.etat === 'requete_signature') {
      chipLabel = 'Requête déposée'
      chipColor = 'success'
    }
    
    row.etat_chip = (
      <Chip
        label={ chipLabel }
        color={ chipColor }
        variant="outlined"
      />
    )

    row.children = (
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(2)
      }}
      >
        <table style={{ borderCollapse: 'collapse' }}>
          <tbody>
            {renderIntervenantTr(
              'Demandeur',
              row.demandeur_nom,
              row.demandeur_siren,
              row.demandeur_gestion
            )}
            {renderIntervenantTr(
              'Défendeur',
              row.defendeur_nom,
              row.defendeur_siren,
              row.defendeur_gestion
            )}
          </tbody>
        </table>
        {/* <Box>{ row.depots }</Box> */}
        <Box sx={{ whiteSpace: 'nowrap' }}>
          {!!row.facture_numero && (
            <Link to={'/facture/' + row.facture_numero}>
              Facture {row.facture_numero}
            </Link>
          )}
          {!!row.evocation_date && (
            <Box>
              <small>Première audience le {dateFr(row.evocation_date)}</small>
            </Box>
          )}
          {!!row.transmission_date && (
            <Box>
              <small>Transmis au juge le {dateFr(row.transmission_date)}</small>
            </Box>
          )}
          {!!row.signature_date && (
            <Box>
              <small>Signé par le juge le {dateFr(row.signature_date)}</small>
            </Box>
          )}
        </Box>
      </Box>
    )

    return (
      <TableRowRcs
        key={row.affaire_numero}
        row={row}
        columns={columns}
      />
    )
  }

  return (
    <PageContent>
      <Headband alignLeft>
        <form style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }} onSubmit={event => handleSubmit(event)}>
          <Typography variant='h1'>Assignations / Requêtes</Typography>
          <Box sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}>
            <DatePicker
              label='Date de début'
              value={dateMin}
              onChange={handleSetDateMin}
              disabled={!!depotNumero || !!affaireNumero}
            />
            <DatePicker
              label='Date de fin'
              value={dateMax}
              onChange={handleSetDateMax}
              disabled={!!depotNumero || !!affaireNumero}
            />
            <FormControl variant='filled'>
              <InputLabel id='type-select-label'>Type d'affaire</InputLabel>
              <Select
                labelId='type-select-label'
                id='type-select'
                value={type}
                onChange={event => setType(event.target.value)}
              >
                <MenuItem value='all'>Tous</MenuItem>
                {typeOptions.sort().map(typeOption => (
                  <MenuItem value={typeOption} key={typeOption}>{typeOption}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}>
            <FormControl variant='filled'>
              <InputLabel htmlFor='affaireNumero'>N° affaire</InputLabel>
              <FilledInput
                id='affaireNumero'
                value={affaireNumero}
                onChange={event => {
                  setAffaireNumero(event.target.value)
                }}
                endAdornment={!!affaireNumero && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Bouton pour vider le champ'
                      onClick={() => {
                        setAffaireNumero('')
                        document.getElementById('affaireNumero').focus()
                      }}
                      edge='end'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <FormControl variant='filled'>
              <InputLabel htmlFor='demandeurDefendeur'>Demandeur / Défendeur</InputLabel>
              <FilledInput
                id='demandeurDefendeur'
                value={demandeurDefendeur}
                onChange={event => {
                  setDemandeurDefendeur(event.target.value)
                }}
                endAdornment={!!demandeurDefendeur && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Bouton pour vider le champ'
                      onClick={() => {
                        setDemandeurDefendeur('')
                        document.getElementById('demandeurDefendeur').focus()
                      }}
                      edge='end'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <FormControl variant='filled'>
              <InputLabel htmlFor='depotNumero'>N° dépôt</InputLabel>
              <FilledInput
                id='depotNumero'
                value={depotNumero}
                onChange={event => {
                  setDepotNumero(event.target.value)
                }}
                endAdornment={!!depotNumero && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Bouton pour vider le champ'
                      onClick={() => {
                        setDepotNumero('')
                        document.getElementById('depotNumero').focus()
                      }}
                      edge='end'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <IconButton
              type='submit'
              aria-label='submit'
              size='large'
              sx={{ marginLeft: theme.spacing(1) }}
            >
              <SearchIcon fontSize='large' />
            </IconButton>
          </Box>
        </form>
      </Headband>
      <ListItems
        name='judiciaire'
        items={filteredItems}
        page={page}
        setPage={setPage}
        renderTableRow={renderTableRow}
        columns={columns}
      />
    </PageContent>
  )
}
