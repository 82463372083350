import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Box, Typography, Button } from '@mui/material'
import Alert from '@mui/material/Alert'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'

import api from './lib/api'
import Logo from './components/Logo'
import WelcomeAside from './components/WelcomeAside'
import { useEffect } from 'react'
import { useRef } from 'react'

export default function CreerUnCompteToken () {
  const { token } = useParams()
  const [alertText, setAlertText] = useState('')
  const [alertSeverity, setAlertSeverity] = useState('info')
  const shouldFetch = useRef(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false
      api.get('user-create-valid/' + encodeURIComponent(token)).then(response => {
        if (response.data.email) {
          setAlertText('Votre compte a été validé')
          setAlertSeverity('success')
        } else {
          setAlertText('Votre compte n\'a pas été créé correctement')
          setAlertSeverity('error')
        }
      }).catch((error) => {
        setAlertText(error.message || 'Erreur lors de la validation de votre compte')
        setAlertSeverity('error')
      })
    }
  }, [])

  const sx = { width: '100%', margin: '.75rem 0' }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <WelcomeAside />
      <Box sx={{ padding: '2rem 6vw', maxHeight: '100vh', overflow: 'hidden', overflowY: 'auto' }}>
        <Box sx={{ width: '432px', padding: '0 16px', margin: 'auto' }}>
          <Logo style={{ margin: '0 auto 3rem' }} />
          <Typography variant='subtitle' sx={{ ...sx, textAlign: 'center' }}>
            Créer un compte
          </Typography>
          {alertText && (
            <Alert severity={alertSeverity} sx={{ ...sx, margin: '2rem 0' }}>{alertText}</Alert>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              startIcon={<ArrowBackOutlinedIcon />}
              onClick={() => { navigate('/connexion') }}
            >
              Retour à la page de connexion
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
