import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs'

import { Alert, Chip, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/SearchOutlined'
import DeleteIcon from '@mui/icons-material/CloseOutlined'
import Box from '@mui/material/Box'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import Headband from './components/Headband'
import ListItems from './components/ListItems'
import TableRowRcs from './components/TableRowRcs'
import { ColorModeContext } from './context/ColorModeContext'
import useTheme from './themes/material'
import DatePicker, { dateMinImportant, dateMaxImportant } from './components/DatePicker'
import dateFr from './lib/date'
import Link from './components/Link'
import BoxClient from './components/BoxClient'
import ButtonPdf from './components/ButtonPdf'
import PageContent from './components/PageContent'

export default function DepotsComptesAnnuels () {
  const app = useContext(AppContext)
  const { colorMode } = useContext(ColorModeContext)
  const theme = useTheme(colorMode)
  const [cookies, setCookie] = useCookies()

  const dateMinDefault = cookies['depotsComptesAnnuels-dateMin'] ? dayjs(cookies['depotsComptesAnnuels-dateMin']) : dayjs(process.env.REACT_APP_DATE_NOW).startOf('month')
  const dateMaxDefault = cookies['depotsComptesAnnuels-dateMax'] ? dayjs(cookies['depotsComptesAnnuels-dateMax']) : dayjs(process.env.REACT_APP_DATE_NOW).endOf('month')

  const [items, setItems] = useState(null)
  const [page, setPage] = useState(0)
  const [depotNumero, setDepotNumero] = useState('')
  const [sirenNumero, setSirenNumero] = useState('')
  const [societeNom, setSocieteNom] = useState('')
  const [dateMin, setDateMin] = useState(dateMinDefault)
  const [dateMax, setDateMax] = useState(dateMaxDefault)
  const [etat, setEtat] = useState('all')
  const [submitCount, setSubmitCount] = useState(0)

  const columns = [
    { code: 'count', label: '#', sort: 'count' },
    { code: 'depot', label: 'N° dépôt', sort: 'depot_numero', sx: { fontWeight: 'bold' } },
    { code: 'type', label: 'Type', sort: 'type_exercice' },
    { code: 'cloture_date', label: 'Clôture', filter: dateFr, sort: 'cloture_date' },
    { code: 'arrivee_date', label: 'Réception', filter: dateFr, sort: 'arrivee_date' },
    // { code: 'saisie_date', label: 'Saisie', filter: dateFr, sort: 'saisie_date' },
    { code: 'etat_chip', label: 'État du dossier', sort: 'etat' }
  ]

  const formIsValid = () => {
    return depotNumero || sirenNumero || societeNom || (
      (dateMin.isValid() && dateMin >= dateMinImportant) &&
      (dateMax.isValid() && dateMax <= dateMaxImportant)
    )
  }

  const apiGetItems = () => {
    if (!formIsValid()) {
      app.snackbarError('Veuillez vérifier les filtres de recherche')
      return
    }

    setItems(null)
    setPage(0)

    api.post('depots-comptes-annuels', {
      client_code: app.user.client_code,
      depot_numero: (depotNumero || '').trim(),
      siren_numero: (sirenNumero || '').trim(),
      societe_nom: (societeNom || '').trim(),
      date_min: (depotNumero ? dateMinImportant : dateMin).format('DD/MM/YYYY'),
      date_max: (depotNumero ? dateMaxImportant : dateMax).format('DD/MM/YYYY')
    }).then(response => {
      setItems(response.data)
    }).catch(error => {
      setItems([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des dépôts de comptes annuels')
    })
  }

  const filteredItems = items === null
    ? null
    : items.filter(item => (
      !etat || etat === 'all' || etat === item.etat ? item : false
    ))

  const handleSetDateMin = newDate => {
    setDateMin(newDate)

    setCookie('depotsComptesAnnuels-dateMin', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSetDateMax = (newDate) => {
    setDateMax(newDate)

    setCookie('depotsComptesAnnuels-dateMax', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmitCount(submitCount + 1)
  }

  useEffect(() => {
    if (submitCount > 0) {
      apiGetItems()
    }
  }, [submitCount])

  useEffect(() => {
    setSubmitCount(submitCount + 1)
  }, [])

  const renderTableRow = (item) => {
    const row = { ...item }

    row.depot = /^(termine)$/.test(row.etat)
      ? (
        <ButtonPdf
          numero={row.depot_numero}
          name='depot-comptes-annuels'
          label='Dépôt de comptes annuels'
          ready={row.index_ged_cle > 0}
        />
        )
      : row.depot_numero

    row.type = row.type_exercice + ' ' + (row.dematerialise ? 'démat.' : 'papier')

    let chipLabel = 'Indéfini'
    let chipColor = 'error'
    if (row.etat === 'termine') {
      chipLabel = 'Terminé le ' + dateFr(row.evt_date)
      chipColor = 'success'
    } else if (row.etat === 'invalide') {
      chipLabel = 'Invalidé le ' + dateFr(row.evt_date)
      chipColor = 'error'
    } else if (row.etat === 'rejete') {
      chipLabel = 'Rejeté le ' + dateFr(row.evt_date)
      chipColor = 'error'
    }

    row.etat_chip = (
      <Chip
        label={chipLabel}
        color={chipColor}
        variant='outlined'
      />
    )

    const motif = !row.motifs && !row.libelle_autres
      ? null
      : (
        <>
          {row.motifs && (
            <ul style={{ marginTop: 0, paddingLeft: theme.spacing(2) }}>
              {
                row.motifs.split('|').map((label, index) => (
                  <li key={index}>{label}</li>
                ))
              }
            </ul>
          )}
          {!!row.libelle_autres && (
            <Box>{row.libelle_autres.replace(/<br\/>/ig, '')}</Box>
          )}
        </>
        )

    row.children = (
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(2)
      }}
      >
        <BoxClient client={row} />
        {row.etat === 'termine' && (
          <Box sx={{ whiteSpace: 'nowrap' }}>
            {!!row.facture_numero && (
              <Link to={'/facture/' + row.facture_numero}>
                Facture {row.facture_numero}
              </Link>
            )}
            {!!row.bodacc_transmission_date && (
              <Box>
                <small>Transmission BODACC {dateFr(row.bodacc_transmission_date)}</small>
              </Box>
            )}
          </Box>
        )}
        {row.etat !== 'termine' && !!motif && (
          <Alert severity='warning' sx={{ mt: 1, mb: 1, maxWidth: '560px' }}>
            {motif}
          </Alert>
        )}
      </Box>
    )

    return (
      <TableRowRcs
        key={row.depot_numero}
        row={row}
        columns={columns}
      />
    )
  }

  return (
    <PageContent>
      <Headband alignLeft>
        <form style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }} onSubmit={event => handleSubmit(event)}>
          <Typography variant='h1'>Dépôts de comptes annuels</Typography>
          <Box sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}>
            <DatePicker
              label='Date de début'
              value={dateMin}
              onChange={handleSetDateMin}
              disabled={!!depotNumero}
            />
            <DatePicker
              label='Date de fin'
              value={dateMax}
              onChange={handleSetDateMax}
              disabled={!!depotNumero}
            />
            <FormControl variant='filled'>
              <InputLabel id='etat-select-label'>État des dossiers</InputLabel>
              <Select
                labelId='etat-select-label'
                id='etat-select'
                value={etat}
                onChange={event => setEtat(event.target.value)}
              >
                <MenuItem value='all'>Tous</MenuItem>
                {/* <MenuItem value='recu'>Reçus au greffe</MenuItem> */}
                <MenuItem value='rejete'>Rejetés</MenuItem>
                <MenuItem value='termine'>Terminés</MenuItem>
                <MenuItem value='invalide'>Invalidés</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}>
            <FormControl variant='filled'>
              <InputLabel htmlFor='depotNumero'>N° dépôt</InputLabel>
              <FilledInput
                id='depotNumero'
                value={depotNumero}
                onChange={event => {
                  setDepotNumero(event.target.value)
                }}
                endAdornment={!!depotNumero && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Bouton pour vider le champ'
                      onClick={() => {
                        setDepotNumero('')
                        document.getElementById('depotNumero').focus()
                      }}
                      edge='end'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <FormControl variant='filled'>
              <InputLabel htmlFor='sirenNumero'>N° Siren</InputLabel>
              <FilledInput
                id='sirenNumero'
                value={sirenNumero}
                onChange={event => {
                  setSirenNumero(event.target.value)
                }}
                endAdornment={!!sirenNumero && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Bouton pour vider le champ'
                      onClick={() => {
                        setSirenNumero('')
                        document.getElementById('sirenNumero').focus()
                      }}
                      edge='end'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <FormControl variant='filled'>
              <InputLabel htmlFor='societeNom'>Nom de la société</InputLabel>
              <FilledInput
                id='societeNom'
                value={societeNom}
                onChange={event => {
                  setSocieteNom(event.target.value)
                }}
                endAdornment={!!societeNom && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Bouton pour vider le champ'
                      onClick={() => {
                        setSocieteNom('')
                        document.getElementById('societeNom').focus()
                      }}
                      edge='end'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <IconButton
              type='submit'
              aria-label='submit'
              size='large'
              sx={{ marginLeft: theme.spacing(1) }}
            >
              <SearchIcon fontSize='large' />
            </IconButton>
          </Box>
        </form>
      </Headband>
      <ListItems
        name='depotsComptesAnnuels'
        items={filteredItems}
        page={page}
        setPage={setPage}
        renderTableRow={renderTableRow}
        columns={columns}
      />
    </PageContent>
  )
}
