import { useContext } from 'react'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Box } from '@mui/system'

import useTheme from '../themes/material'
import { ColorModeContext } from '../context/ColorModeContext'
import LinkSiren from './LinkSiren'

export default function TableRowFacture ({ row, columns }) {
  const { colorMode } = useContext(ColorModeContext)
  const theme = useTheme(colorMode)

  row = { ...row }

  const sxPaper = {
    backgroundColor: theme.palette.background.paper
  }
  if (colorMode === 'dark') {
    sxPaper.backgroundImage = 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'
  }

  return (
    <>
      <TableRow>
        {columns.filter(column => column.active !== false).map(column => {
          let content = row[column.code] || column.content || ''
          if (typeof column.filter === 'function') {
            content = column.filter(content)
          }

          return (
            <TableCell
              key={column.code}
              align={column.align || 'left'}
              sx={{ ...column.sx, backgroundColor: `${theme.palette.primary.main}11` }}
            >
              {content}
            </TableCell>
          )
        })}
      </TableRow>
      {row.children && (
        <TableRow>
          <TableCell component='th' sx={sxPaper} />
          <TableCell
            colSpan={columns.length - 1}
            sx={{
              ...sxPaper,
              paddingTop: '0',
              paddingBottom: '0',
              whiteSpace: 'normal',
              textAlign: 'left'
            }}
          >
            {row.children.map((child, index) => (
              <Box key={index} sx={{ borderTop: index > 0 ? `1px solid ${theme.palette.divider}` : 'none', padding: theme.spacing(1.5, 0) }}>
                {index + 1}. {child.tarif_libelle} <em>{getTextWithSiren(child.article_libelle)}</em>
              </Box>
            ))}
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell colSpan={columns.length + 1} sx={{ height: theme.spacing(4) }} />
      </TableRow>
    </>
  )

  function getTextWithSiren (text) {
    const regExp = /^(.*)(\d{4}[A-Z]\d{5})(\s\/\s)(\d{9})(.*)$/i
    if (!regExp.test(text)) {
      return text
    }

    const textSplit = text.split(regExp)
    return (
      <>
        {textSplit[1]}
        {textSplit[2]}
        {textSplit[3]}
        <LinkSiren gestionNumero={textSplit[2]} sirenNumero={textSplit[4]} />
        {textSplit[5] || ''}
      </>
    )
  }
}
