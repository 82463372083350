import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs'

import { FormControl, IconButton, InputLabel, Typography, Select, MenuItem } from '@mui/material'
import SearchIcon from '@mui/icons-material/SearchOutlined'
import Box from '@mui/material/Box'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import DatePicker from './components/DatePicker'
import Headband from './components/Headband'
import MyTableRow from './components/TableRow'
import ListItems from './components/ListItems'
import useTheme from './themes/material'
import PageContent from './components/PageContent'

export default function FrequentationParGreffe () {
  const app = useContext(AppContext)
  const theme = useTheme()
  const [cookies, setCookie] = useCookies()

  const dateMinDefault = cookies['frequentationParGreffe-dateMin']
    ? dayjs(cookies['frequentationParGreffe-dateMin'])
    : dayjs(process.env.REACT_APP_DATE_NOW).startOf('month')

  const dateMaxDefault = cookies['frequentationParGreffe-dateMax']
    ? dayjs(cookies['frequentationParGreffe-dateMax'])
    : dayjs(process.env.REACT_APP_DATE_NOW).endOf('month')

  const [items, setItems] = useState(null)
  const [page, setPage] = useState(0)
  const [dateMin, setDateMin] = useState(dateMinDefault)
  const [dateMax, setDateMax] = useState(dateMaxDefault)
  const [greffes, setGreffes] = useState([])
  const [users, setUsers] = useState(null)
  const [greffeSelected, setGreffeSelected] = useState('all')
  const [userSelected, setUserSelected] = useState('all')
  const [showUsers, setShowUsers] = useState(false)

  const columns = [
    { code: 'count', label: '#', sort: 'count' },
    { code: 'greffe', label: 'Greffe', sort: 'greffeCode' },
    // { code: 'date', label: 'Mois', sort: 'date' },
    { code: 'userFullName', label: 'Utilisateur', sort: 'userFullName', active: showUsers },
    { code: 'frequentation', label: 'Fréquentation', sort: 'frequentation', footer: 'sum', align: 'right' }
  ]

  const getSysLogs = () => {
    setItems(null)
    setPage(0)

    api.post('frequentation-par-greffe', {
      greffe_id: greffeSelected === 'all' ? 0 : greffeSelected,
      user_id: (greffeSelected === 'all' || userSelected === 'all') ? 0 : userSelected,
      date_min: dateMin.format('DD/MM/YYYY'),
      date_max: dateMax.format('DD/MM/YYYY')
    }).then(response => {
      setItems(response.data)
    }).catch(error => {
      setItems([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des logs')
    })
  }

  const updateUsers = () => {
    setShowUsers(greffeSelected !== 'all')

    if (items === null) {
      return
    }

    const usersArray = []
    const ids = {}

    items.map(item => {
      if (typeof ids[item.userId] === 'undefined') {
        ids[item.userId] = true
        usersArray.push({
          id: item.userId,
          fullName: item.userFullName
        })
      }
      return item
    })

    usersArray.sort((a, b) => {
      const aValue = typeof a.fullName === 'string' ? a.fullName.toLowerCase() : a.fullName
      const bValue = typeof b.fullName === 'string' ? b.fullName.toLowerCase() : b.fullName

      if (aValue > bValue) return 1
      if (aValue < bValue) return -1
      return 0
    })

    setUsers(usersArray)
  }

  const getGreffes = () => {
    api.get('applications-espace-client').then(response => {
      setGreffes(response.data)
    }).catch(error => {
      setGreffes([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des greffes')
    })
  }

  const handleSetDateMin = (newDate) => {
    setDateMin(newDate)

    setCookie('frequentationParGreffe-dateMin', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSetDateMax = (newDate) => {
    setDateMax(newDate)

    setCookie('frequentationParGreffe-dateMax', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    getSysLogs()
  }

  useEffect(getSysLogs, [])

  useEffect(getGreffes, [])

  useEffect(updateUsers, [items])

  const renderTableRow = (item) => {
    const row = { ...item }

    row.data = item

    return (
      <MyTableRow
        key={row.id}
        row={row}
        columns={columns}
      />
    )
  }

  return (
    <PageContent>
      <Headband alignLeft>
        <form style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }} onSubmit={event => handleSubmit(event)}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
            <Typography variant='h1' sx={{ display: 'inline-block', margin: '0' }}>Fréquentation par greffe</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: theme.spacing(4), alignItems: 'center' }}>
            <DatePicker
              label='Date de début'
              value={dateMin}
              onChange={handleSetDateMin}
            />
            <DatePicker
              label='Date de fin'
              value={dateMax}
              onChange={handleSetDateMax}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: theme.spacing(4), zzjustifyContent: 'space-between', alignItems: 'center' }}>
            <FormControl variant='filled'>
              <InputLabel id='greffe-select-label'>Greffe</InputLabel>
              <Select
                labelId='greffe-select-label'
                id='greffe-select'
                value={greffeSelected}
                onChange={event => setGreffeSelected(event.target.value)}
              >
                <MenuItem value='all'>Tous</MenuItem>
                {greffes && greffes.map(greffe => {
                  return <MenuItem key={greffe.id} value={greffe.id}>{greffe.code} - Greffe {greffe.city}</MenuItem>
                })}
              </Select>
            </FormControl>
            {showUsers && (
              <FormControl variant='filled'>
                <InputLabel id='user-select-label'>Utilisateur</InputLabel>
                <Select
                  labelId='user-select-label'
                  id='user-select'
                  value={userSelected}
                  onChange={event => setUserSelected(event.target.value)}
                >
                  <MenuItem value='all'>Tous</MenuItem>
                  {users && users.map(user => {
                    return <MenuItem key={user.id} value={user.id}>{user.fullName}</MenuItem>
                  })}
                </Select>
              </FormControl>
            )}
            <IconButton
              type='submit'
              aria-label='submit'
              size='large'
            >
              <SearchIcon fontSize='large' />
            </IconButton>
          </Box>
        </form>
      </Headband>
      <ListItems
        name='frequentationParGreffe'
        items={items}
        page={page}
        setPage={setPage}
        renderTableRow={renderTableRow}
        columns={columns}
      />
    </PageContent>
  )
}
