import { useContext } from 'react'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import useTheme from '../themes/material'
import { ColorModeContext } from '../context/ColorModeContext'

export default function TableRowRcs ({ row, columns }) {
  const { colorMode } = useContext(ColorModeContext)
  const theme = useTheme(colorMode)

  row = { ...row }

  const sxPaper = {
    backgroundColor: theme.palette.background.paper
  }
  if (colorMode === 'dark') {
    sxPaper.backgroundImage = 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'
  }

  return (
    <>
      <TableRow>
        {columns.filter(column => column.active !== false).map(column => {
          let content = row[column.code] || column.content || ''
          if (typeof column.filter === 'function') {
            content = column.filter(content)
          }

          return (
            <TableCell
              key={column.code}
              align={column.align || 'left'}
              sx={{ ...column.sx, backgroundColor: `${theme.palette.primary.main}11` }}
            >
              {content}
            </TableCell>
          )
        })}
      </TableRow>
      {row.children && (
        <TableRow>
          <TableCell component='th' sx={sxPaper} />
          <TableCell
            colSpan={columns.length - 1}
            sx={{
              ...sxPaper,
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              whiteSpace: 'normal',
              textAlign: 'left'
            }}
          >
            {row.children}
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell colSpan={columns.length + 1} sx={{ height: theme.spacing(4) }} />
      </TableRow>
    </>
  )
}
