import React, { useContext, useState } from 'react'

import { Alert, Box, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Switch, TextField } from '@mui/material'

import useTheme from '../themes/material'
import { AppContext } from '../context/AppContext'
import api from '../lib/api'

export default function DialogUtilisateur ({ user, open, setOpen }) {
  const app = useContext(AppContext)
  const theme = useTheme()

  let defaultClientOption = {
    id: '',
    label: ''
  }
  app.clientOptions.map(option => {
    if (option.id === user.client_code) {
      defaultClientOption = option
    }
    return option
  })

  const [clientOption, setClientOption] = useState(defaultClientOption)
  const [firstname, setFirstname] = useState(user.firstname || '')
  const [name, setName] = useState(user.name || '')
  const [email, setEmail] = useState(user.email || '')
  const [username, setUsername] = useState(user.username || '')
  const [admin, setAdmin] = useState(!!user.admin)
  const [active, setActive] = useState(!!user.active)

  const userCreate = () => {
    const createdUser = {
      client_code: clientOption?.id || '',
      firstname,
      name,
      email,
      admin,
      active
    }

    api.post('user', createdUser).then(() => {
      setOpen(false)
    }).catch(error => {
      app.snackbarError(error.message || 'Erreur lors de la création de l\'utilisateur')
    })
  }

  const userUpdate = () => {
    const updatedUser = {
      id: user.id,
      client_code: clientOption?.id || '',
      firstname,
      name,
      email,
      admin,
      active
    }

    api.post('user/' + updatedUser.id, updatedUser).then(response => {
      if (updatedUser.id === app.user.id) {
        app.init(response.data)
      }
      setOpen(false)
    }).catch(error => {
      app.snackbarError(error.message || 'Erreur lors de la modification de l\'utilisateur')
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    user.id ? userUpdate() : userCreate()
  }

  const sxTextfield = {
    display: 'block',
    margin: theme.spacing(2, 0)
  }

  const renderField = (field, setField, key, label, required, autoFocus) => (
    <TextField
      id={key}
      label={label}
      variant='filled'
      value={field}
      onChange={event => {
        setField(event.target.value)
      }}
      required={required}
      autoFocus={autoFocus}
      fullWidth
      sx={sxTextfield}
    />
  )

  const me = user.id && user.id === app.user.id

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth='sm'
    >
      <form onSubmit={(event) => handleSubmit(event)}>
        <DialogTitle>Modification de l'utilisateur</DialogTitle>
        <DialogContent>
          <Autocomplete
            disablePortal
            id='client'
            value={clientOption}
            disabled={me && !admin}
            onChange={(event, newOption) => {
              setClientOption(newOption)
            }}
            options={app.clientOptions}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={option.client_active ? {} : {
                  color: theme.palette.secondary.main
                }}
                {...props}
              >
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Client'
                variant='filled'
                required={!admin}
              />
            )}
          />
          {renderField(firstname, setFirstname, 'name', 'Prénom', true)}
          {renderField(name, setName, 'name', 'Nom', true)}
          {renderField(email, value => {
            setEmail(value)
            if (!value) {
              setUsername('')
            } else if (/^0000-/.test(user.username)) {
              setUsername('0000-' + value)
            } else {
              setUsername(app.greffe.code + '-' + value)
            }
          }, 'email', 'Email', true)}
          <Alert severity="info">
            <small>Identifiant de connexion{/^0000-/.test(user.username) ? '' : ' (la saisie du code greffe est facultative)'}</small>
            <Box>{username}</Box>
          </Alert>
          {!me && !!app.user.admin && (
            <FormGroup sx={{ margin: theme.spacing(4, 0) }}>
              <FormControlLabel
                control={<Switch checked={admin} onChange={() => setAdmin(!admin)} />}
                label='Admin'
              />
              <FormControlLabel
                control={<Switch checked={active} onChange={() => setActive(!active)} />}
                label='Actif'
              />
            </FormGroup>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Annuler</Button>
          <Button type='submit'>Enregistrer</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
