import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs'

import { Chip, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/SearchOutlined'
import DeleteIcon from '@mui/icons-material/CloseOutlined'
import Box from '@mui/material/Box'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import Headband from './components/Headband'
import ListItems from './components/ListItems'
import TableRowRcs from './components/TableRowRcs'
import { ColorModeContext } from './context/ColorModeContext'
import useTheme from './themes/material'
import DatePicker, { dateMinImportant, dateMaxImportant } from './components/DatePicker'
import dateFr from './lib/date'
import Link from './components/Link'
import BoxClient from './components/BoxClient'
import ButtonPdf from './components/ButtonPdf'
import PageContent from './components/PageContent'

export default function Bodacc () {
  const app = useContext(AppContext)
  const { colorMode } = useContext(ColorModeContext)
  const theme = useTheme(colorMode)
  const [cookies, setCookie] = useCookies()

  const dateMinDefault = cookies['bodacc-dateMin'] ? dayjs(cookies['bodacc-dateMin']) : dayjs(process.env.REACT_APP_DATE_NOW).startOf('month')
  const dateMaxDefault = cookies['bodacc-dateMax'] ? dayjs(cookies['bodacc-dateMax']) : dayjs(process.env.REACT_APP_DATE_NOW).endOf('month')

  const [items, setItems] = useState(null)
  const [page, setPage] = useState(0)
  const [avisNumero, setAvisNumero] = useState('')
  const [sirenNumero, setSirenNumero] = useState('')
  const [societeNom, setSocieteNom] = useState('')
  const [dateMin, setDateMin] = useState(dateMinDefault)
  const [dateMax, setDateMax] = useState(dateMaxDefault)
  const [etat, setEtat] = useState('all')
  const [submitCount, setSubmitCount] = useState(0)

  const columns = [
    { code: 'count', label: '#', sort: 'count' },
    { code: 'avis', label: 'N° avis', sort: 'avis_numero', sx: { fontWeight: 'bold' } },
    { code: 'bodacc_numero', label: 'N° BODACC', sort: 'bodacc_numero' },
    { code: 'bodacc_type', label: 'Type', sort: 'bodacc_type' },
    { code: 'saisie_date', label: 'Date de dépôt', filter: dateFr, sort: 'saisie_date' },
    { code: 'etat_chip', label: 'État du dossier', sort: 'etat' }
  ]

  const formIsValid = () => {
    return avisNumero || sirenNumero || societeNom || (
      (dateMin.isValid() && dateMin >= dateMinImportant) &&
      (dateMax.isValid() && dateMax <= dateMaxImportant)
    )
  }

  const apiGetItems = () => {
    if (!formIsValid()) {
      app.snackbarError('Veuillez vérifier les filtres de recherche')
      return
    }

    setItems(null)
    setPage(0)

    api.post('bodacc', {
      client_code: app.user.client_code,
      avis_numero: (avisNumero || '').trim(),
      siren_numero: (sirenNumero || '').trim(),
      societe_nom: (societeNom || '').trim(),
      date_min: (avisNumero ? dateMinImportant : dateMin).format('DD/MM/YYYY'),
      date_max: (avisNumero ? dateMaxImportant : dateMax).format('DD/MM/YYYY')
    }).then(response => {
      setItems(response.data)
    }).catch(error => {
      setItems([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des avis BODACC')
    })
  }

  const filteredItems = items === null
    ? null
    : items.filter(item => (
      !etat || etat === 'all' || etat === item.etat ? item : false
    ))

  const handleSetDateMin = newDate => {
    setDateMin(newDate)

    setCookie('bodacc-dateMin', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSetDateMax = newDate => {
    setDateMax(newDate)

    setCookie('bodacc-dateMax', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmitCount(submitCount + 1)
  }

  useEffect(() => {
    if (submitCount > 0) {
      apiGetItems()
    }
  }, [submitCount])

  useEffect(() => {
    setSubmitCount(submitCount + 1)
  }, [])

  const renderTableRow = (item) => {
    const row = { ...item }

    row.avis = row.etat === 'publie'
      ? (
        <ButtonPdf
          numero={row.avis_numero}
          name='bodacc'
          label='Avis Bodacc'
          ready={row.index_ged_cle > 0}
        />
        )
      : row.avis_numero

    let etatLabel = 'Indéfini'
    let etatColor = 'error'
    if (row.etat === 'depose') {
      etatLabel = 'Déposé'
      etatColor = 'warning'
    } else if (row.etat === 'rejete') {
      etatLabel = 'Rejeté le ' + dateFr(row.rejet_date)
      etatColor = 'error'
    } else if (row.etat === 'transmis') {
      etatLabel = 'Transmis le ' + dateFr(row.transmission_date)
      etatColor = 'primary'
    } else if (row.etat === 'publie') {
      etatLabel = 'Publié le ' + dateFr(row.publication_date)
      etatColor = 'success'
    }

    row.etat_chip = (
      <Chip
        label={etatLabel}
        color={etatColor}
        variant='outlined'
      />
    )

    row.children = (
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(2)
      }}
      >
        <BoxClient client={row} />
        {!!row.facture_numero && (
          <Link to={'/facture/' + row.facture_numero}>
            Facture {row.facture_numero}
          </Link>
        )}
      </Box>
    )

    return (
      <TableRowRcs
        key={row.avis_numero}
        row={row}
        columns={columns}
      />
    )
  }

  return (
    <PageContent>
      <Headband alignLeft>
        <form style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }} onSubmit={event => handleSubmit(event)}>
          <Typography variant='h1'>BODACC : Avis provisoires</Typography>
          <Box sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}>
            <DatePicker
              label='Date de début'
              value={dateMin}
              onChange={handleSetDateMin}
              disabled={!!avisNumero}
            />
            <DatePicker
              label='Date de fin'
              value={dateMax}
              onChange={handleSetDateMax}
              disabled={!!avisNumero}
            />
            <FormControl variant='filled'>
              <InputLabel id='etat-select-label'>État des dossiers</InputLabel>
              <Select
                labelId='etat-select-label'
                id='etat-select'
                value={etat}
                onChange={event => setEtat(event.target.value)}
              >
                <MenuItem value='all'>Tous</MenuItem>
                <MenuItem value='depose'>Reçus au greffe</MenuItem>
                <MenuItem value='rejete'>Rejetés</MenuItem>
                <MenuItem value='transmis'>Transmis</MenuItem>
                <MenuItem value='publie'>Publiés</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}>
            <FormControl variant='filled'>
              <InputLabel htmlFor='avisNumero'>N° avis</InputLabel>
              <FilledInput
                id='avisNumero'
                value={avisNumero}
                onChange={event => {
                  setAvisNumero(event.target.value)
                }}
                endAdornment={!!avisNumero && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Bouton pour vider le champ'
                      onClick={() => {
                        setAvisNumero('')
                        document.getElementById('avisNumero').focus()
                      }}
                      edge='end'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <FormControl variant='filled'>
              <InputLabel htmlFor='sirenNumero'>N° Siren</InputLabel>
              <FilledInput
                id='sirenNumero'
                value={sirenNumero}
                onChange={event => {
                  setSirenNumero(event.target.value)
                }}
                endAdornment={!!sirenNumero && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Bouton pour vider le champ'
                      onClick={() => {
                        setSirenNumero('')
                        document.getElementById('sirenNumero').focus()
                      }}
                      edge='end'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <FormControl variant='filled'>
              <InputLabel htmlFor='societeNom'>Nom de la société</InputLabel>
              <FilledInput
                id='societeNom'
                value={societeNom}
                onChange={event => {
                  setSocieteNom(event.target.value)
                }}
                endAdornment={!!societeNom && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Bouton pour vider le champ'
                      onClick={() => {
                        setSocieteNom('')
                        document.getElementById('societeNom').focus()
                      }}
                      edge='end'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <IconButton
              type='submit'
              aria-label='submit'
              size='large'
              sx={{ marginLeft: theme.spacing(1) }}
            >
              <SearchIcon fontSize='large' />
            </IconButton>
          </Box>
        </form>
      </Headband>
      <ListItems
        name='bodacc'
        items={filteredItems}
        page={page}
        setPage={setPage}
        renderTableRow={renderTableRow}
        columns={columns}
      />
    </PageContent>
  )
}
