import TablePagination from '@mui/material/TablePagination'

export default function Pagination (props) {
  const tablePaginationProps = { ...props }
  delete tablePaginationProps.name
  delete tablePaginationProps.setView

  const myLabelDisplayedRows = ({ from, to, count }) => (
    `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`
  )

  return (
    <TablePagination
      {...tablePaginationProps}
      rowsPerPageOptions={[10, 20, 50]}
      component='div'
      labelRowsPerPage='Lignes par page'
      labelDisplayedRows={myLabelDisplayedRows}
      showFirstButton
      showLastButton
      sx={{
        width: { xs: '100%', lg: 'calc(100% - 276px)' },
        left: { xs: '0', lg: '276px' },
        gap: { xs: '1rem', md: '2rem' },
        flexDirection: { xs: 'column', sm: 'row' }
      }}
    />
  )
}
