import { SvgIcon } from '@mui/material'

export default function IconSort (props) {
  return (
    <SvgIcon {...props}>
      <path d='M8,10l4-4l4,4H8z' />
      <path d='M16,14l-4,4l-4-4H16z' />
    </SvgIcon>
  )
}
