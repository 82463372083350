import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { Link as MuiLink, Alert, Button, FormControlLabel, Paper, Switch, Tab, Tabs, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined'
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import BusinessIcon from '@mui/icons-material/Business'
import PersonIcon from '@mui/icons-material/PersonOutlined'
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'

import { AppContext } from './context/AppContext'
import { ColorModeContext } from './context/ColorModeContext'
import Headband from './components/Headband'
import useTheme from './themes/material'
import MainContainer from './components/MainContainer'
import DialogClient from './components/DialogClient'
import DialogUtilisateur from './components/DialogUtilisateur'
import MyFab from './components/Fab'
import Link from './components/Link'
import MotDePasseInfo from './components/MotDePasseInfo'
import BoxClientAvecProvision from './components/BoxClientAvecProvision'

export default function GestionDuCompte () {
  const app = useContext(AppContext)
  const { colorMode, toggleColorMode } = useContext(ColorModeContext)
  const theme = useTheme(colorMode)
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState(0)
  const [userOpen, setUserOpen] = useState(false)
  const [clientOpen, setClientOpen] = useState(false)

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const client = app.client || {
    id: 0,
    code: '',
    label: 'Non renseigné'
  }

  function TabPanel ({ index, value, children }) {
    return (
      <Box
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        sx={{
          pt: theme.spacingResponsive,
          pb: theme.spacingResponsive
        }}
      >
        {children}
      </Box>
    )
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  }

  function a11yProps (index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const BigIcon = styled(Box)({
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: '100%'
  })

  const sxIcon = {
    fontSize: 40,
    display: 'block'
  }

  const sxFlex = {
    display: 'flex',
    gap: theme.spacingResponsive,
    justifyContent: 'space-between',
    alignItems: 'start'
  }

  function tableRow (label, value) {
    if (!value) {
      return <></>
    }
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant='caption'>{label}</Typography>
        <Typography variant='h6' sx={{ fontWeight: 'normal' }}>{value}</Typography>
      </Box>
    )
  }

  const greffeEmail = app?.greffe?.email || ''
  const emailSubject = 'Demande  de compte avec provision (client ' + client.code + ' - ' + client.label + ')'

  return (
    <Box>
      <Headband alignLeft>
        <Typography variant='h1'>
          Gestion du compte
        </Typography>
      </Headband>
      <MainContainer condensed>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleChange} aria-label='basic tabs example'>
            <Tab label='Compte client' {...a11yProps(0)} />
            <Tab label='Informations personnelles' {...a11yProps(1)} />
            <Tab label='Mot de passe' {...a11yProps(2)} />
            <Tab label='Préférences' {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={activeTab} index={0}>
          <Box sx={sxFlex}>
            <Box>
              {!client.code && (
                <Alert severity='warning'>
                  Aucun compte client n'est associé à votre utilisateur
                </Alert>
              )}
              {!!client.code && !client.id && (
                <Alert severity='warning'>
                  Le compte client {client.code} est inexistant
                </Alert>
              )}
              {!!client.code && !!client.id && (
                <Box>
                  <Box>
                    {tableRow('Code', client.code)}
                    {tableRow('Nom du client', client.label)}
                    {tableRow('Case courrier', client.case_courrier)}
                    {tableRow('Adresse postale', client.adresse)}
                    {tableRow('Numéro de téléphone', client.bureau)}
                    {tableRow('Email de facturation', client.email)}
                  </Box>
                  <Box sx={{ pt: 6 }}>
                    {client.avec_provision === 0 && (
                      <>
                        <Paper
                          elevation={0}
                          sx={{
                            padding: theme.spacing(5),
                            textAlign: 'center',
                            display: 'inline-block'
                          }}
                        >
                          <ReceiptIcon fontSize='large' />
                          <Typography>Client sans provision</Typography>
                          <Button
                            variant='outlined'
                            sx={{ marginTop: theme.spacing(3) }}
                            onClick={() => navigate('/releves-de-factures', { state: { paiementEtat: 'nonSolde' } })}
                          >
                            Afficher les relevés de<br />factures non soldés
                          </Button>
                        </Paper>
                        {/* <Alert severity='info' sx={{ mt: 3 }}>
                          Si vous souhaitez créer un compte avec provision, merci de vous rapprocher du greffe
                        </Alert> */}
                        {!!greffeEmail && app.greffe.code !== '8501' && (
                          <Alert severity='info' sx={{ mt: 3, p: theme.spacing(2) }}>
                            Vous souhaitez créer un compte avec provision ?
                            <p>
                              Faites-nous la demande par email en cliquant sur le lien ci-dessous.
                              <br />
                              Nous vous activerons la fonctionnalité.
                            </p>
                            <MuiLink
                              href={`mailto:${greffeEmail}?subject=${emailSubject}`}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: theme.spacing(1)
                              }}
                            >
                              <EmailOutlinedIcon />
                              Demander un compte avec provision
                            </MuiLink>
                          </Alert>
                        )}
                      </>
                    )}
                    {client.avec_provision === 1 && (
                      <BoxClientAvecProvision />
                    )}
                  </Box>
                  {!!app.greffe?.gestion_compte && (
                    <>
                      <MyFab
                        icon={<EditIcon />}
                        label='Modifier le compte client'
                        onClick={() => setClientOpen(true)}
                      />
                      <DialogClient open={clientOpen} setOpen={setClientOpen} />
                    </>
                  )}
                </Box>
              )}
            </Box>
            <BigIcon>
              <BusinessIcon color='disabled' sx={sxIcon} />
            </BigIcon>
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Box sx={sxFlex}>
            <Box>
              {tableRow('Compte client', client.code + ' - ' + client.label + (client.active ? '' : ' (inactif)'))}
              {tableRow('Prénom', app.user.firstname)}
              {tableRow('Nom', app.user.name)}
              {tableRow('Email', app.user.email)}
              {tableRow('Admin', app.user.admin ? 'Oui' : 'Non')}
              <Alert severity="info">
                <small>Identifiant de connexion{/^0000\-/.test(app.user.username) ? '' : ' (la saisie du code greffe est facultative)'}</small>
                <Box>{app.user.username}</Box>
              </Alert>
            </Box>
            <BigIcon>
              <PersonIcon color='disabled' sx={sxIcon} />
            </BigIcon>
          </Box>
          <MyFab
            icon={<EditIcon />}
            label='Modifier les informations personnelles'
            onClick={() => setUserOpen(true)}
          />
          <DialogUtilisateur user={app.user} open={userOpen} setOpen={setUserOpen} />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Box sx={sxFlex}>
            <MotDePasseInfo />
            <BigIcon>
              <KeyOutlinedIcon color='disabled' sx={sxIcon} />
            </BigIcon>
          </Box>
          <Link to='/mot-de-passe-update'>
            <MyFab
              icon={<EditIcon />}
              label='Modifier le mot de passe'
            />
          </Link>
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <Box sx={sxFlex}>
            <Box>
              <FormControlLabel
                control={<Switch checked={colorMode === 'dark'} onChange={toggleColorMode} />}
                label='Mode sombre'
              />
            </Box>
            <BigIcon>
              <SettingsOutlinedIcon color='disabled' sx={sxIcon} />
            </BigIcon>
          </Box>
        </TabPanel>
      </MainContainer>
    </Box>
  )
}
