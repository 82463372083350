import React, { useState } from 'react'
import { useCookies } from 'react-cookie'

import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import Pagination from './Pagination'
import AlertNoResult from './AlertNoResult'
import TableSkeleton from './TableSkeleton'
import MainContainer from './MainContainer'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { TableFooter, Tooltip } from '@mui/material'
import IconSort from './IconSort'

export default function ListItems ({ name, items, view, setView, page, setPage, renderTableRow, columns, children }) {
  const [cookies, setCookie] = useCookies()

  const [rowsPerPage, setRowsPerPage] = useState(cookies[name + '-rowsPerPage'] * 1 || 10)
  const [sortColumn, setSortColumn] = useState(columns[0].sort === 'count' ? 'count' : '')
  const [sortDirection, setSortDirection] = useState(columns[0].sort === 'count' ? 'ASC' : '')

  const sxNoPadding = { padding: '0' }

  if (items === null) {
    return <TableSkeleton columns={columns} />
  } else if (typeof items !== 'object' || items.length === 0) {
    return <AlertNoResult />
  }

  let count = 0
  const footer = {}
  items.map(item => {
    if (!item.noCount) {
      item.count = ++count
    }

    columns.filter(column => column.active !== false).map(column => {
      if (column.footer !== 'sum') {
        return
      } else if (typeof footer[column.code] === 'undefined') {
        footer[column.code] = 0
      }
      footer[column.code] += item[column.code]
    })
    return item
  })
  const hasFooter = Object.keys(footer).length > 0

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)

    setCookie(name + '-rowsPerPage', event.target.value, {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const sortedItems = [].concat(items).sort((a, b) => {
    if (a.noCount || b.noCount) {
      // Ligne d'entête en premier
      // Ligne de pied en dernier
      return 0
    } else if (sortColumn === '' || sortDirection === '') {
      // Aucun tri
      return 0
    }

    let aValue = a[sortColumn]
    if (typeof aValue === 'string') {
      aValue = aValue.toLowerCase()
    } else if (aValue === null) {
      aValue = ''
    } else if (typeof aValue === 'object' && aValue.date) {
      aValue = aValue.date
    } else if (typeof aValue === 'number') {
      aValue = 1 * aValue
    }

    let bValue = b[sortColumn]
    if (typeof bValue === 'string') {
      bValue = bValue.toLowerCase()
    } else if (bValue === null) {
      bValue = ''
    } else if (typeof bValue === 'object' && bValue.date) {
      bValue = bValue.date
    } else if (typeof bValue === 'number') {
      bValue = 1 * bValue
    }

    if (aValue === bValue) {
      return 0
    } else if (aValue > bValue) {
      return (sortDirection === 'ASC' ? 1 : -1)
    }
    return (sortDirection === 'ASC' ? -1 : 1)
  })

  const filteredRows = page === false
    ? sortedItems
    : sortedItems.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )

  const sort = function (columnCode) {
    let newColumn = columnCode
    let newDirection = 'ASC'

    if (newColumn === sortColumn && sortDirection === 'ASC') {
      newDirection = 'DESC'
    } else if (newColumn === sortColumn && sortDirection === 'DESC') {
      newColumn = ''
      newDirection = ''
    }

    setSortColumn(newColumn)
    setSortDirection(newDirection)
    setPage(0)
  }

  return (
    <>
      <MainContainer condensed maxWidth={false} sx={{ mb: '52px', flexGrow: 1, paddingTop: 0 }}>
        <Table
          id='list-items'
          sx={{ minWidth: 650 }}
          aria-label='Table principale'
        >
          <TableHead sx={{ position: 'sticky', top: 0 }}>
            <TableRow>
              {columns.filter(column => column.active !== false).map(column => (
                <TableCell
                  key={column.code}
                  component='th'
                  align={column.align || 'left'}
                  sx={column.code === 'pdf' ? sxNoPadding : null}
                >
                  {!!column.sort && (
                    <Tooltip title='Trier'>
                      <Box
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          sort(column.sort)
                        }}
                      >
                        <span>{column.label}</span>
                        {
                          column.sort === sortColumn && sortDirection !== ''
                            ? (
                                sortDirection === 'ASC'
                                  ? <ArrowDropUp fontSize='small' />
                                  : <ArrowDropDown fontSize='small' />
                              )
                            : <IconSort fontSize='small' />
                        }
                      </Box>
                    </Tooltip>
                  )}
                  {!column.sort && column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows && filteredRows.map(row => {
              return renderTableRow(row)
            })}
          </TableBody>
          {hasFooter && (
            <TableFooter>
              <TableRow>
                {columns.filter(column => column.active !== false).map(column => (
                  <TableCell
                    key={column.code}
                    align={column.align || 'left'}
                    sx={column.sx}
                  >
                    {footer[column.code] || ''}
                  </TableCell>
                ))}
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {children || null}
      </MainContainer>
      {page !== false && (
        <Pagination
          name={name}
          count={items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          view={view}
          setView={setView}
        />
      )}
    </>
  )
}
