import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Skeleton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { AppContext } from './context/AppContext'
import MyCard from './components/Card'
import useTheme from './themes/material'
import Headband from './components/Headband'
import api from './lib/api'
import dayjs from 'dayjs'
import Actualite from './components/Actualite'
import MainContainer from './components/MainContainer'
import WelcomeTitle from './components/WelcomeTitle'
import MyFab from './components/Fab'

const dateNow = dayjs(process.env.REACT_APP_DATE_NOW)

export default function Accueil () {
  const app = useContext(AppContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const [items, setItems] = useState(null)

  useEffect(() => {
    api.get('informations').then(response => {
      setItems(response.data)
    }).catch(error => {
      setItems([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des actualités')
    })
  }, [])

  const sxGrid = {
    display: 'grid',
    gap: theme.spacingResponsive,
    gridTemplateColumns: {
      xs: 'repeat(1, 1fr)',
      md: 'repeat(1, 1fr)',
      lg: 'repeat(1, 1fr)'
    }
  }

  return (
    <>
      <Headband>
        <WelcomeTitle />
      </Headband>
      {/* <Box sx={{ padding: '2rem 0', backgroundColor: `${theme.palette.primary.main}11` }}>
        <Box sx={{ maxWidth: '560px', padding: '2rem', margin: 'auto', textAlign: 'center' }}>
          <Typography variant="h2" sx={{ marginTop: 0 }}>
                Retrouvez l'ensemble de vos documents au même endroit
              </Typography>
              <Typography variant="body1" color={ theme.palette.primary.main }>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.
                Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed,
                consectetur adipiscing elit.
              </Typography>
              <Button variant="contained" size="large" sx={{ margin: '2rem 1rem 0 0' }} onClick={ event => {
                navigate('/factures')
              }}>
                Factures
              </Button>
              <Button variant="contained" size="large" sx={{ margin: '2rem 0 0 0' }}>
                Comptes annuels
              </Button>
        </Box>
      </Box> */}
      <MainContainer>
        <Box sx={sxGrid}>
          {items === null && ([1, 2, 3, 4, 5, 6].map(index => (
            <MyCard
              key={index}
              title={<Skeleton height={80} />}
              subheader={<Skeleton width={160} />}
              description={<Skeleton height={240} />}
            />
          )))}
          {items && items.map((item, index) => {
            const activeStart = !item.activeStart || !dayjs(item.activeStart).isValid ? null : dayjs(item.activeStart)
            const activeEnd = !item.activeEnd || !dayjs(item.activeEnd).isValid ? null : dayjs(item.activeEnd)

            if (!item.active || (activeStart && dateNow < activeStart) || (activeEnd && activeEnd < dateNow)) {
              return null
            }

            return (
              <Actualite key={index} item={item} editabled={!!app.user.admin} />
            )
          })}
        </Box>
        {!!app.user.admin && (
          <MyFab
            icon={<AddIcon />}
            label='Ajouter une actualité'
            ariaLabel='add'
            onClick={() => navigate('/gestion-des-actualites/edition')}
          />
        )}
      </MainContainer>
    </>
  )
}
