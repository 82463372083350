/*
 * @param Date|string date
 * @return Date
 */
const myDate = (date) => {
  return date === 'now' ? new Date() : new Date(date)
}

/*
 * @param Date|string date
 * @param string defaultString
 * @return string
 */
export const dateTimeFr = (date, defaultString) => {
  try {
    date = myDate(date)
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
  } catch (error) {}

  return defaultString || ''
}

/*
 * @param Date|string date
 * @param string defaultString
 * @return string
 */
const dateFr = (date, defaultString) => {
  try {
    if (!date) {
      throw new Error()
    }

    return myDate(date).toLocaleDateString()
  } catch (error) {}

  return defaultString || ''
}

export default dateFr

/*
 * @param number monthNumber
 * @return string
 */
export const monthName = (monthNumber) => {
  const months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ]
  return months[monthNumber - 1] || ''
}
