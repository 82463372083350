import React from 'react'
import { Container } from '@mui/material'
import useTheme from '../themes/material'

export default function MainContainer ({ maxWidth, sx, condensed, children }) {
  const theme = useTheme()
  const spacing = condensed ? theme.spacingResponsiveCondensed : theme.spacingResponsive

  sx = sx || {}
  sx.paddingTop = typeof sx.paddingTop === 'undefined' ? spacing : sx.paddingTop
  sx.paddingBottom = typeof sx.paddingBottom === 'undefined' ? spacing : sx.paddingBottom

  return (
    <Container sx={sx} maxWidth={typeof maxWidth === 'undefined' ? 'xl' : maxWidth}>
      {children}
    </Container>
  )
}
