import { useContext, useState } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownloadOutlined'
// import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDoneOutlined'
import LoadingButton from './LoadingButton'
import api from '../lib/api'
import { AppContext } from '../context/AppContext'
import useTheme from '../themes/material'
import { Box, Tooltip } from '@mui/material'

export default function ButtonPdf ({ numero, name, label, ready }) {
  const app = useContext(AppContext)
  const theme = useTheme()
  const [buttonState, setButtonState] = useState(ready ? 'ready' : 'init')
  const fileUrl = api.getBaseURL() + name + '-pdf/' + app.user.client_code + '/' + numero

  const handlePdfOpen = () => {
    app.openDialogEmbed(fileUrl, label + ' ' + numero)
  }

  const handlePdfGenerate = () => {
    setButtonState('loading')
    api.get(name + '-pdf-generate/' + app.user.client_code + '/' + numero).then(() => {
      setButtonState('ready')
      handlePdfOpen()
    }).catch((error) => {
      setButtonState('init')
      app.snackbarError(error.message || 'Erreur lors la génération du PDF')
    })
  }

  let title = 'Consulter le document'
  if (name === 'releve-de-factures') {
    title = 'Consulter le relevé de factures'
  } else if (name === 'facture') {
    title = 'Consulter la ' + label
  } else if (name === 'depot-actes-statuts') {
    title = 'Consulter le récépissé de dépôt d\'actes'
  } else if (name === 'depot-comptes-annuels') {
    title = 'Consulter le duplicata de récépissé de dépot des comptes et bilans annuels'
  } else if (name === 'bodacc') {
    title = 'Consulter l\'avis de publication BODACC'
  }

  return (
    <Tooltip title={title}>
      <Box>
        <LoadingButton
          onClick={buttonState === 'ready' ? handlePdfOpen : handlePdfGenerate}
          endIcon={<FileDownloadIcon />}
          variant='contained'
          size='small'
          sx={{
            padding: theme.spacing(0.75, 1.5),
            borderRadius: '64px'
          }}
          loading={buttonState === 'loading'}
        >
          {numero}
        </LoadingButton>
      </Box>
    </Tooltip>
  )
}
