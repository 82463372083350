import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Alert, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import { AppContext } from './context/AppContext'
import Headband from './components/Headband'
import useTheme from './themes/material'
import DatePicker from './components/DatePicker'
import TextEditor from './components/TextEditor'
import './styles/ckeditor.css'
import api from './lib/api'
import dateFr from './lib/date'
import dayjs from 'dayjs'
import Actualite from './components/Actualite'
import MainContainer from './components/MainContainer'

export default function EditionActualite () {
  const app = useContext(AppContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const { state } = useLocation()

  const information = { ...state }

  const [titre, setTitre] = useState(information.titre || '')
  const [description] = useState(information.description || '')
  const [texte, setTexte] = useState(information.texte || '')
  const [active, setActive] = useState(!!information.active)
  const [activeStart, setActiveStart] = useState(information.activeStart ? dayjs(information.activeStart) : null)
  const [activeEnd, setActiveEnd] = useState(information.activeEnd ? dayjs(information.activeEnd) : null)
  const [auteur, setAuteur] = useState(information.auteur || (information.id ? null : app.user.id))
  const [publishedAt, setPublishedAt] = useState(
    information.publishedAt
      ? dayjs(information.publishedAt)
      : (information.id ? null : dayjs())
  )
  const [confirmationDialogDeleteOpen, setConfirmationDialogDeleteOpen] = useState(false)

  const getInformationData = () => ({
    id: information.id || null,
    titre,
    description,
    texte,
    active,
    activeStart: activeStart ? activeStart.format('YYYY-MM-DD') : null,
    activeEnd: activeEnd ? activeEnd.format('YYYY-MM-DD') : null,
    auteur,
    publishedAt: publishedAt ? publishedAt.format('YYYY-MM-DD') : null
  })

  const informationCreate = () => {
    api.post('information', getInformationData()).then(response => {
      navigate(-1)
    }).catch(error => {
      app.snackbarError(error.message || 'Erreur lors de la création de l\'actualité')
    })
  }

  const informationUpdate = () => {
    api.post('information/' + information.id, getInformationData()).then(response => {
      navigate(-1)
    }).catch(error => {
      app.snackbarError(error.message || 'Erreur lors de la modification de l\'actualité')
    })
  }

  const informationDelete = () => {
    api.post('information-delete/' + information.id).then(response => {
      navigate(-1)
      app.snackbarSuccess('L\'actualité a bien été supprimée')
    }).catch(error => {
      app.snackbarError(error.message || 'Erreur lors de la suppression de l\'actualité')
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    information.id ? informationUpdate() : informationCreate()
  }

  const renderField = (field, setField, key, label, required, autoFocus, sx) => (
    <TextField
      id={key}
      label={label}
      value={field}
      onChange={event => {
        setField(event.target.value)
      }}
      variant='filled'
      fullWidth
      sx={sx}
      required={required}
      autoFocus={autoFocus}
    />
  )

  return (
    <Box>
      <Headband alignLeft>
        <Typography variant='h1'>
          {titre || 'Actualité'}
        </Typography>
        {information.id && (
          <Typography>Créé le {dateFr(information.createdAt)} - Modifié le {dateFr(information.updatedAt)}</Typography>
        )}
      </Headband>
      <MainContainer>
        <form onSubmit={event => handleSubmit(event)}>
          {renderField(titre, setTitre, 'titre', 'Titre', true, !information.id, { width: '100%' })}
          <FormGroup sx={{ margin: theme.spacing(3, 0), gap: theme.spacing(2), flexDirection: 'row' }}>
            <FormControl variant='filled' sx={{ minWidth: '240px' }} required>
              <InputLabel id='auteur-select-label'>Auteur</InputLabel>
              <Select
                labelId='auteur-select-label'
                id='auteur-select'
                value={auteur}
                label='Auteur'
                onChange={event => setAuteur(event.target.value)}
              >
                {app.auteurOptions && app.auteurOptions.map((option, index) => (
                  <MenuItem key={index} value={option.id}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <DatePicker
              label='Date de publication'
              value={publishedAt}
              required
              onChange={newDate => {
                setPublishedAt(newDate)
              }}
            />
            <Alert severity='info' sx={{ alignItems: 'center' }}>
              Les actualités sont triées par date de publication décroissante
            </Alert>
          </FormGroup>
          <Box className='ck-container-md' sx={{ margin: theme.spacing(3, 0) }}>
            {/* <Typography variant='h6' sx={{ marginBottom: theme.spacing(1) }}>Description</Typography>
            <TextEditor
              data={ description }
              onChange={ ( event, editor ) => {
                  setDescription(editor.getData())
              } }
            /> */}
          </Box>
          <Box className='ck-container-lg' sx={{ margin: theme.spacing(3, 0) }}>
            {/* <Typography variant='h6' sx={{ marginBottom: theme.spacing(1) }}>Texte</Typography> */}
            <TextEditor
              data={texte}
              onChange={(event, editor) => {
                setTexte(editor.getData())
              }}
            />
          </Box>
          <FormGroup sx={{ margin: theme.spacing(3, 0), gap: theme.spacing(2), flexDirection: 'row' }}>
            <FormControlLabel
              control={<Switch checked={active} onChange={() => setActive(!active)} />}
              label='Affichage actif'
            />
            <DatePicker
              label='Du'
              value={activeStart}
              disabled={!active}
              onChange={newDate => {
                setActiveStart(newDate)
              }}
            />
            <DatePicker
              label='Au'
              value={activeEnd}
              disabled={!active}
              onChange={newDate => {
                setActiveEnd(newDate)
              }}
            />
          </FormGroup>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            gap: theme.spacing(1),
            marginTop: theme.spacing(4)
          }}
          >
            {information.id && (
              <>
                <Button
                  onClick={() => { setConfirmationDialogDeleteOpen(true) }}
                  color='error'
                  endIcon={<DeleteOutlinedIcon />}
                >
                  Supprimer l'actualité
                </Button>
                <Dialog
                  maxWidth='xs'
                  open={confirmationDialogDeleteOpen}
                >
                  <DialogTitle>Attention</DialogTitle>
                  <DialogContent>
                    Vous êtes sur le point de supprimer l'actualité.
                    <br />
                    Cette action est irreversible.
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={() => { setConfirmationDialogDeleteOpen(false) }}>
                      Annuler
                    </Button>
                    <Button onClick={informationDelete}>Supprimer l'actualité</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            <Button onClick={() => navigate(-1)}>Annuler</Button>
            <Button type='submit' variant='contained'>Enregistrer</Button>
          </Box>
        </form>
        <Divider sx={{ padding: theme.spacing(4, 0) }}>
          <Chip label='PRÉVISUALISATION' />
        </Divider>
        <Actualite item={{
          id: information.id,
          titre,
          description,
          texte,
          active,
          activeStart,
          activeEnd,
          auteur,
          publishedAt
        }}
        />
      </MainContainer>
    </Box>
  )
}
