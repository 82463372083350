import React, { useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import FilledInput from '@mui/material/FilledInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import IconVisibility from '@mui/icons-material/Visibility'
import IconPersonOutlined from '@mui/icons-material/PersonOutline'
import IconVisibilityOff from '@mui/icons-material/VisibilityOff'
import Alert from '@mui/material/Alert'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import Link from './components/Link'
import WelcomeAside from './components/WelcomeAside'
import Logo from './components/Logo'
import MotDePasseInfo from './components/MotDePasseInfo'

export default function MotDePasseReset () {
  const app = useContext(AppContext)
  const { userId, token, username } = useParams()

  const [email, setEmail] = useState((username || '').replace(app.greffe.code + '-', ''))
  const [password, setPassword] = useState('')
  const [passwordBis, setPasswordBis] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [isValidBis, setIsValidBis] = useState(password === passwordBis)
  const [alertText, setAlertText] = useState('')
  const [alertSeverity, setAlertSeverity] = useState('info')
  const [showPassword, setShowPassword] = useState(false)

  const navigate = useNavigate()

  const handleChangePassword = (event) => {
    setPassword(event.target.value)
    setAlertText('')
    setAlertSeverity('info')

    setIsValid(
      /.{12,}/.test(event.target.value) && // 12 caractères minimum
      /[A-Z]+/.test(event.target.value) && // 1 lettre majuscule minimum
      /[a-z]+/.test(event.target.value) && // 1 lettre minuscule minimum
      /\d+/.test(event.target.value) && // 1 chiffre minimum
      /[#{}()[\]+\-*$?!]+/.test(event.target.value) // 1 caractère spécial minimum # { } ( ) [ ] + - * _ $ ? !
    )
    setIsValidBis(event.target.value === passwordBis)
  }

  const handleChangePasswordBis = (event) => {
    setPasswordBis(event.target.value)
    setAlertText('')
    setAlertSeverity('info')

    setIsValidBis(password === event.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    api.post('reset-password/' + userId + '/' + token, { email, password, passwordBis }).then(response => {
      app.snackbarSuccess('Le mot de passe a bien été enregistré')
      navigate('/connexion', { replace: true })
    }).catch((error) => {
      setAlertText(error.message || 'Erreur lors de la modification du mot de passe')
      setAlertSeverity('error')
    })
  }

  const sx = { width: '100%', margin: '.75rem 0' }
  const sxFlex = { height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <WelcomeAside />
      <Box sx={{ padding: '2rem 6vw', maxHeight: '100vh', overflow: 'hidden', overflowY: 'auto' }}>
        <Box sx={{ width: '432px', padding: '0 16px', margin: 'auto' }}>
          <Logo style={{ margin: '0 auto 3rem' }} />
          <Typography variant='subtitle' sx={{ ...sx, textAlign: 'center' }}>Modification du mot de passe</Typography>
          <form onSubmit={event => handleSubmit(event)}>
            <TextField
              label='Identifiant (Email)'
              variant='filled'
              value={email}
              sx={sx}
              required
              autoFocus
              onChange={event => {
                setEmail(event.target.value)
                setAlertText('')
                setAlertSeverity('info')
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconPersonOutlined />
                  </InputAdornment>
                )
              }}
            />
            <FormControl variant='filled' required sx={sx}>
              <InputLabel htmlFor='password'>Nouveau mot de passe</InputLabel>
              <FilledInput
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handleChangePassword}
                error={!isValid}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <IconVisibilityOff /> : <IconVisibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl variant='filled' required sx={sx}>
              <InputLabel htmlFor='password'>Confirmation du nouveau mot de passe</InputLabel>
              <FilledInput
                type={showPassword ? 'text' : 'password'}
                value={passwordBis}
                onChange={handleChangePasswordBis}
                error={!isValidBis}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <IconVisibilityOff /> : <IconVisibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <MotDePasseInfo sx={sx} />
            {alertText && <Alert severity={alertSeverity} sx={sx}>{alertText}</Alert>}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1.5rem' }}>
              <Button
                variant='contained'
                size='large'
                type='submit'
                disabled={!email || !password || !passwordBis || alertSeverity !== 'info'}
              >
                Valider
              </Button>
              <Link to='/connexion'>Connexion</Link>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  )
}
