/*
 * @param string value
 * @return boolean
 */
export const passwordValidator = password => {
  return (
    /.{12,}/.test(password) && // 12 caractères minimum
    /[A-Z]+/.test(password) && // 1 lettre majuscule minimum
    /[a-z]+/.test(password) && // 1 lettre minuscule minimum
    /\d+/.test(password) && // 1 chiffre minimum
    /[#{}()[\]+\-*$?!]+/.test(password) // 1 caractère spécial minimum # { } ( ) [ ] + - * _ $ ? !
  )
}
