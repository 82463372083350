import { useContext, useState } from 'react'

import { styled } from '@mui/material/styles'
import { Card, CardActions, CardContent, CardHeader, Collapse, IconButton, Tooltip, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/EditOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { AppContext } from '../context/AppContext'
import { useNavigate } from 'react-router-dom'

const editUrl = '/gestion-des-actualites/edition'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

export default function MyCard ({ title, subheader, description, children, item }) {
  const app = useContext(AppContext)
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  if (typeof description === 'string') {
    description = (
      <Typography
        variant='body2'
        color='text.secondary'
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )
  }

  if (typeof children === 'string') {
    children = (
      <Typography
        variant='body'
        dangerouslySetInnerHTML={{ __html: children }}
      />
    )
  }

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
      <CardHeader
        zzaction={
          <IconButton aria-label='settings'>
            <MoreVertIcon />
          </IconButton>
        }
        title={title}
        subheader={subheader}
      />
      {/*
      <CardMedia
        component="img"
        height="194"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"
      />
      */}
      <CardContent sx={{ flexGrow: 1 }}>
        {description}
      </CardContent>
      {(children || app.user.admin) && (
        <CardActions disableSpacing sx={{ justifyContent: 'end' }}>
          {!!app.user.admin && (
            <Tooltip title='Éditer les valeurs'>
              <IconButton onClick={() => { navigate(editUrl, { state: item }) }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='Plus de détail'
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
      )}
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          {children}
        </CardContent>
      </Collapse>
    </Card>
  )
}
