import React, { useContext, useState } from 'react'
import { useCookies } from 'react-cookie'

import { Box, Typography, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/SearchOutlined'

import { AppContext } from './context/AppContext'
import useTheme from './themes/material'
import Headband from './components/Headband'
import dayjs from 'dayjs'
import MainContainer from './components/MainContainer'
import DatePicker, { dateMinImportant, dateMaxImportant } from './components/DatePicker'
import DossiersClotures from './components/statistiques/DossiersClotures'
import DossiersDeposes from './components/statistiques/DossiersDeposes'

export default function Statistiques () {
  const app = useContext(AppContext)
  const theme = useTheme()
  const [cookies, setCookie] = useCookies()

  const dateMinDefault = cookies['statistiques-dateMin'] ? dayjs(cookies['statistiques-dateMin']) : dayjs(process.env.REACT_APP_DATE_NOW).startOf('month')
  const dateMaxDefault = cookies['statistiques-dateMax'] ? dayjs(cookies['statistiques-dateMax']) : dayjs(process.env.REACT_APP_DATE_NOW).endOf('month')

  const [dateMin, setDateMin] = useState(dateMinDefault)
  const [dateMax, setDateMax] = useState(dateMaxDefault)
  const [dateMinSaved, setDateMinSaved] = useState(dateMinDefault)
  const [dateMaxSaved, setDateMaxSaved] = useState(dateMaxDefault)

  const formIsValid = () => {
    return (
      (dateMin.isValid() && dateMin >= dateMinImportant) &&
      (dateMax.isValid() && dateMax <= dateMaxImportant)
    )
  }

  const handleSetDateMin = newDate => {
    setDateMin(newDate)

    setCookie('statistiques-dateMin', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSetDateMax = newDate => {
    setDateMax(newDate)

    setCookie('statistiques-dateMax', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (!formIsValid()) {
      app.snackbarError('Veuillez vérifier les filtres de recherche')
      return
    }

    setDateMinSaved(dateMin)
    setDateMaxSaved(dateMax)
  }

  return (
    <>
      <Headband alignLeft>
        <form style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }} onSubmit={event => handleSubmit(event)}>
          <Typography variant='h1'>Statistiques</Typography>
          <Box sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}>
            <DatePicker
              label='Date de début'
              value={dateMin}
              onChange={handleSetDateMin}
            />
            <DatePicker
              label='Date de fin'
              value={dateMax}
              onChange={handleSetDateMax}
            />
            <IconButton
              type='submit'
              aria-label='submit'
              size='large'
              sx={{ marginLeft: theme.spacing(1) }}
            >
              <SearchIcon fontSize='large' />
            </IconButton>
          </Box>
        </form>
      </Headband>
      <MainContainer>
        <DossiersClotures dateMin={dateMinSaved} dateMax={dateMaxSaved} />
      </MainContainer>
      <MainContainer>
        <DossiersDeposes dateMin={dateMinSaved} dateMax={dateMaxSaved} />
      </MainContainer>
    </>
  )
}
