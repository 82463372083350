import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import api from './lib/api'
import Loader from './components/Loader'
import AlertNoResult from './components/AlertNoResult'
import { AppContext } from './context/AppContext'
import Headband from './components/Headband'
import useTheme from './themes/material'

export default function ChangementDeGreffe () {
  const [items, setItems] = useState(null)
  const app = useContext(AppContext)
  const theme = useTheme()

  useEffect(() => {
    api.get('applications-espace-client').then(response => {
      setItems(response.data)
    }).catch(error => {
      setItems([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des applications Espace client')
    })
  }, [])

  if (items === null) {
    return (
      <Box sx={{
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      >
        <Loader center />
      </Box>
    )
  }

  const sxCard = {
    display: 'inline-block',
    marginTop: theme.spacingResponsive,
    marginLeft: theme.spacingResponsive,
    padding: theme.spacing(2, 0),
    minWidth: '300px',
    textAlign: 'center'
  }

  return (
    <Box>
      <Headband>
        <Typography variant='h1'>Changement de greffe</Typography>
      </Headband>
      {items.length === 0 && <AlertNoResult />}
      {items.length > 0 && items.map((item, index) => {
        const currentGreffe = app.greffe.code === item.code

        return (
          <Card key={index} sx={sxCard}>
            <CardContent>
              <Typography variant='h5' component='div'>
                {item.code}
              </Typography>
              <Typography variant='body2'>
                Greffe {item.city}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button
                size='small'
                variant='contained'
                href={item.url}
                component='a'
                disabled={currentGreffe}
              >
                {currentGreffe ? 'Greffe en cours' : 'Choisir'}
              </Button>
            </CardActions>
          </Card>
        )
      })}
    </Box>
  )
}
