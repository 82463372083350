import { useContext } from 'react'
import { AppContext } from './../context/AppContext'
import { ColorModeContext } from '../context/ColorModeContext'

export default function Logo ({ style }) {
  const app = useContext(AppContext)
  const { colorMode } = useContext(ColorModeContext)
  const url = '/logo' + (colorMode === 'dark' ? '/dark' : '') + '/logo-greffe-' + app.greffe.code + '.png'

  return (
    <img
      src={url}
      height='60'
      width='227'
      alt='Logo Greffe du Tribunal de Commerce'
      style={{ ...style, display: 'block' }}
    />
  )
}
