const locale = 'fr-FR'

/*
 * @param int|float|string value
 * @return string
 */
export const siren = value => {
  try {
    const valueFormatted = numberToString(value) || ''
    if (valueFormatted.length !== 9) {
      console.error('Les numéros Siren doivent être sur 9 caractères', value)
      return valueFormatted
    }
    return valueFormatted.substring(0, 3) + ' ' +
           valueFormatted.substring(3, 6) + ' ' +
           valueFormatted.substring(6, 9)
  } catch (error) {
    console.error('Impossible de formatter ce numéro Siren', value, error)
    return ''
  }
}

/*
 * @param int|float|string value
 * @return string
 */
export const int = value => {
  return number(value).toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}

/*
 * @param int|float|string value
 * @return string
 */
export const float2decimals = value => {
  return number(value).toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

/*
 * @param int|float|string value
 * @return string
 */
export const percent = value => {
  return number(value).toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: 'percent'
  })
}

/*
 * @param int|float|string value
 * @return string
 */
export const percent2decimals = value => {
  return number(value).toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'percent'
  })
}

/*
 * @param int|float|string value
 * @return string
 */
export const euro = value => {
  return floatAmount(value).toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'EUR'
  })
}

/*
 * @param int|float|string value
 * @return string
 */
export const euroOrEmpty = value => {
  value = floatAmount(value)
  return value === 0 ? '' : euro(value)
}

/*
 * @param int|float|string value
 * @param string negativeColor
 * @param string positiveColor
 * @return string
 */
export const euroOrEmptyWithColor = (value, negativeColor, positiveColor) => {
  value = floatAmount(value)
  if (value === 0) {
    return ''
  } else if (value < 0) {
    return <span style={{ color: negativeColor }}>{euro(value)}</span>
  } else {
    return <span style={{ color: positiveColor }}>{euro(value)}</span>
  }
}

function number (value) {
  return Number(value || 0)
}

function floatAmount (value) {
  value = number(value)
  value = Math.abs(value) < 0.005 ? 0 : value
  return value
}

function numberToString (value) {
  return (value || '').toString().replace(/\s/g, '')
}
