import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { Box, Typography } from '@mui/material'
import { Bar } from '@nivo/bar'

import { AppContext } from './../../context/AppContext'
import useTheme from './../../themes/material'
import api from './../../lib/api'
import { monthName } from '../../lib/date'
import { int, percent } from '../../lib/format'
import MyTable from '../Table'
import TableRow from '../TableRow'
import Link from '../Link'

export default function DossiersClotures ({ dateMin, dateMax }) {
  const app = useContext(AppContext)
  const theme = useTheme()

  const [items, setItems] = useState(null)
  const [itemFooter, setItemFooter] = useState(null)

  const columns = [
    { code: 'periode', label: 'Période' },
    { code: 'nb_total_element', label: <>Formalités<br />déposées</> },
    {
      code: 'nb_validees_element',
      label: <>Formalités validées<br />sans rejet</>,
      sx: { color: theme.palette.success.main }
    },
    {
      code: 'nb_rejets_element',
      label: <>Formalités<br />en rejet</>,
      sx: { color: theme.palette.error.main }
    },
    {
      code: 'nb_en_cours_element',
      label: <>Formalités<br />en cours</>,
      sx: { color: theme.palette.warning.main }
    }
  ]

  useEffect(() => {
    api.post('statistiques/dossiers-deposes', {
      client_code: app.user.client_code,
      date_min: dateMin.format('DD/MM/YYYY'),
      date_max: dateMax.format('DD/MM/YYYY')
    }).then(response => {
      const rows = response.data

      if (!rows || !rows.length) {
        setItems([])
        return
      }

      let nbTotal = 0
      let nbValidees = 0
      let nbEnCours = 0
      let nbRejets = 0

      rows.map(row => {
        row.periode = monthName(row.mois) + ' ' + row.annee
        row.periodeMin = dayjs(row.annee + '-' + row.mois.toString().padStart(2, '0') + '-01').startOf('month')
        row.periodeMax = dayjs(row.annee + '-' + row.mois.toString().padStart(2, '0') + '-01').endOf('month')
        if (row.periodeMin < dateMin) {
          row.periodeMin = dateMin
        }
        if (row.periodeMax > dateMax) {
          row.periodeMax = dateMax
        }

        nbTotal += row.nb_total
        nbValidees += row.nb_validees
        nbEnCours += row.nb_en_cours
        nbRejets += row.nb_rejets

        return row
      })

      setItemFooter({
        periode: 'Total',
        periodeMin: dateMin,
        periodeMax: dateMax,
        nb_total: nbTotal,
        nb_validees: nbValidees,
        nb_en_cours: nbEnCours,
        nb_rejets: nbRejets,
        sx: { fontWeight: 'bold' }
      })

      setItems(rows)
    }).catch(error => {
      setItems([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des statistiques de dossiers déposés')
    })
  }, [dateMin, dateMax])

  const renderTableRow = (item) => {
    const row = { ...item }

    row.nb_total_element = !row.nb_total
      ? '0'
      : (
        <Link
          to={'/rcs/' + row.periodeMin.format('YYYY-MM-DD') + '/' + row.periodeMax.format('YYYY-MM-DD')}
          sx={{ display: 'inline-block', pt: 1, pb: 1, pl: 2, pr: 2 }}
        >
          {int(row.nb_total)}
        </Link>
        )

    row.nb_validees_element = (
      <>
        {int(row.nb_validees)}
        <Typography variant='caption' sx={{ ml: 1 }}>
          ({percent(row.nb_validees / row.nb_total)})
        </Typography>
      </>
    )

    row.nb_en_cours_element = (
      <>
        {int(row.nb_en_cours)}
        <Typography variant='caption' sx={{ ml: 1 }}>
          ({percent(row.nb_en_cours / row.nb_total)})
        </Typography>
      </>
    )

    row.nb_rejets_element = (
      <>
        {int(row.nb_rejets)}
        <Typography variant='caption' sx={{ ml: 1 }}>
          ({percent(row.nb_rejets / row.nb_total)})
        </Typography>
      </>
    )

    return (
      <TableRow
        key={row.annee + row.mois}
        row={row}
        columns={columns}
      />
    )
  }

  return (
    <>
      <Typography variant='h2'>Vos dossiers déposés sur la période</Typography>
      <MyTable
        items={items === null ? null : (items.length === 0 ? [] : items.concat(itemFooter))}
        page={false}
        renderTableRow={renderTableRow}
        columns={columns}
      />
      {!!items && items.length > 0 && (
        <Box sx={{ width: '600px', margin: '0 auto' }}>
          <Bar
            data={items}
            keys={[
              'nb_validees',
              'nb_en_cours',
              'nb_rejets'
            ]}
            indexBy='periode'
            width={600}
            height={320}
            colors={[
              theme.palette.success.main,
              theme.palette.warning.main,
              theme.palette.error.main
            ]}
          />
        </Box>
      )}
    </>
  )
}
