import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'

import WelcomeAside from './components/WelcomeAside'
import Logo from './components/Logo'

export default function MotDePasseExpire () {
  const navigate = useNavigate()

  const sx = { width: '100%', margin: '.75rem 0' }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <WelcomeAside />
      <Box sx={{ padding: '2rem 6vw', maxHeight: '100vh', overflow: 'hidden', overflowY: 'auto' }}>
        <Box sx={{ width: '432px', padding: '0 16px', margin: 'auto', textAlign: 'center' }}>
          <Logo style={{ margin: '0 auto 3rem' }} />
          <Typography variant='subtitle' sx={{ ...sx, textAlign: 'center' }}>Mot de passe expiré</Typography>
          <Box sx={{ margin: '1rem 0 2rem' }}>
            Votre mot de passe est arrivé à expiration.
            <br />Pour pouvoir vous connecter à l'espace client,
            <br />vous devez au préalable modifier votre mot de passe.
          </Box>
          <Button
            variant='contained'
            size='large'
            onClick={() => navigate('/mot-de-passe-update')}
          >
            Modifier mon mot de passe
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
