import React, { useState, useContext, useRef, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import { Box, Typography, Link as MuiLink } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import FilledInput from '@mui/material/FilledInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import IconPersonOutlined from '@mui/icons-material/PersonOutline'
import IconVisibility from '@mui/icons-material/Visibility'
import IconVisibilityOff from '@mui/icons-material/VisibilityOff'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import Alert from '@mui/material/Alert'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import Logo from './components/Logo'
import Link from './components/Link'
import WelcomeAside from './components/WelcomeAside'
import BoxClientAvecProvision from './components/BoxClientAvecProvision'
import useTheme from './themes/material'
import Captcha from './components/Captcha'

export default function Login (props) {
  const app = useContext(AppContext)
  const { defaultEmail } = useParams()
  const theme = useTheme()
  const forgottenPasswordText = 'Une demande de modification de mot de passe va vous être envoyée par email'
  const recaptcha = useRef()

  const [email, setEmail] = useState(defaultEmail || process.env.REACT_APP_EMAIL || '')
  const [password, setPassword] = useState(process.env.REACT_APP_PASSWORD || '')
  const [forgottenPassword, setForgottenPassword] = useState(!!props.forgottenPassword)
  const [alertText, setAlertText] = useState(forgottenPassword ? forgottenPasswordText : '')
  const [alertSeverity, setAlertSeverity] = useState('info')
  const [showPassword, setShowPassword] = useState(false)
  const [captchaValue, setCaptchaValue] = useState('')
  const [uniqId, setUniqId] = useState('')
  const [canSubmit, setCanSubmit] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  let from = location.state?.from?.pathname
  from = !from || from === '/' ? '/accueil' : from

  useEffect(() => {
    setCanSubmit(!!email && !!captchaValue && alertSeverity === 'info')
  }, [email, captchaValue, alertSeverity])

  const handleToggleForgottenPassword = () => {
    setForgottenPassword(!forgottenPassword)
    setAlertText(forgottenPassword ? '' : forgottenPasswordText)
    setAlertSeverity('info')
  }

  const getUsername = () => (/^\d{4}-/.test(email.trim()) ? email.trim() : (app.greffe.code + '-' + email.trim()))

  const handleChangeCaptcha = (event) => {
    setAlertText(forgottenPassword ? '' : forgottenPasswordText)
    setAlertSeverity('info')
    setCaptchaValue(event.currentTarget.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (forgottenPassword) {
      submitForgottenPassword()
    } else {
      submitLogin()
    }
  }

  const submitLogin = () => {
    api.post('login', {
      username: getUsername(),
      password
    }).then(response => {
      app.init(response.data, () => {
        navigate(from, { replace: true })
        if (!!response?.data?.client?.avec_provision && response?.data?.client?.solde <= 0) {
          app.openDialog(<BoxClientAvecProvision />)
        }
      })
    }).catch((error) => {
      setAlertText(error.response?.status === 401 || !error.message ? 'Erreur lors de la connexion' : error.message)
      setAlertSeverity('error')
    })
  }

  const submitForgottenPassword = () => {
    if (!captchaValue) {
      setAlertText('Vous devez cocher la case ci-dessus')
      setAlertSeverity('error')
      return
    }

    api.post('forgotten-password', {
      username: getUsername(),
      captchaValue
    }).then(response => {
      setCaptchaValue('')
      setUniqId(Math.floor(Math.random() * 1000000))
      setAlertText(response.data.message || "Vous allez recevoir l'email dans quelques instants")
      setAlertSeverity('success')
    }).catch(error => {
      setCaptchaValue('')
      setUniqId(Math.floor(Math.random() * 1000000))
      setAlertText(error.message || 'Erreur lors de la demande de modification de mot de passe')
      setAlertSeverity('error')
    })
  }

  const sx = { width: '100%', margin: '.75rem 0' }
  const sxButton = { minWidth: '200px' }
  const greffeRgpdEmail = app.greffe.rgpd_email

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <WelcomeAside />
      <Box sx={{ padding: '2rem 6vw', maxHeight: '100vh', overflow: 'hidden', overflowY: 'auto' }}>
        <Box sx={{ width: '432px', padding: '0 16px', margin: 'auto' }}>
          <Logo style={{ margin: '0 auto 3rem' }} />
          <Typography variant='subtitle' sx={{ ...sx, textAlign: 'center' }}>
            {forgottenPassword ? 'Mot de passe oublié' : 'Connexion'}
          </Typography>
          <form onSubmit={event => handleSubmit(event)}>
            <TextField
              id='email'
              label='Identifiant (Email)'
              variant='filled'
              value={email}
              required
              sx={sx}
              autoFocus
              onChange={event => {
                setEmail(event.target.value)
                setAlertText(forgottenPassword ? forgottenPasswordText : '')
                setAlertSeverity('info')
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconPersonOutlined />
                  </InputAdornment>
                )
              }}
            />
            {!forgottenPassword && (
              <FormControl variant='filled' required sx={sx}>
                <InputLabel htmlFor='password'>Mot de passe</InputLabel>
                <FilledInput
                  id='password'
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={event => {
                    setPassword(event.target.value)
                    setAlertText(forgottenPassword ? forgottenPasswordText : '')
                    setAlertSeverity('info')
                  }}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPassword(!showPassword)}
                        edge='end'
                      >
                        {showPassword ? <IconVisibilityOff /> : <IconVisibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Mot de passe'
                />
              </FormControl>
            )}
            {forgottenPassword && (
              <Captcha uniqId={uniqId} value={captchaValue} onChange={handleChangeCaptcha} />
            )}
            {alertText && <Alert severity={alertSeverity} sx={sx}>{alertText}</Alert>}
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '1.5rem',
              gap: theme.spacing(2)
            }}
            >
              {!forgottenPassword && (
                <>
                  <Link onClick={handleToggleForgottenPassword} to='/mot-de-passe-oublie'>Mot de passe oublié ?</Link>
                  <Button variant='contained' size='large' type='submit' disabled={!email || !password} sx={sxButton}>
                    Me connecter
                  </Button>
                </>
              )}
              {forgottenPassword && alertSeverity !== 'success' && (
                <>
                  <Link onClick={handleToggleForgottenPassword} to='/connexion'>Annuler</Link>
                  <Button variant='contained' sx={sxButton} size='large' type='submit' disabled={!canSubmit}>
                    Valider
                  </Button>
                </>
              )}
              {forgottenPassword && alertSeverity === 'success' && (
                <Button
                  startIcon={<ArrowBackOutlinedIcon />}
                  onClick={() => {
                    handleToggleForgottenPassword()
                    navigate('/connexion')
                  }}
                >
                  Retour à la page de connexion
                </Button>
              )}
            </Box>
            {!forgottenPassword && app.greffe.code === '8501' /* 8501 5906 */ && (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '1rem',
                gap: theme.spacing(2)
              }}>
                <Typography color='secondary'>Pas encore de compte ?</Typography>
                <Button
                  variant='contained'
                  size='small'
                  color='secondary'
                  sx={sxButton}
                  onClick={() => {
                    navigate(`/creer-un-compte/${encodeURIComponent(email)}`)
                  }}
                >
                  Créer un compte
                </Button>
              </Box>
            )}
          </form>
          <Box sx={{ mt: 4, lineHeight: 1.1, textAlign: 'justify' }}>
            <Typography variant='caption'>
              En application du RGPD (Règlement général sur la protection des données),
              vous disposez d'un droit d'accès et d'opposition que vous pouvez exercer à
              l'adresse suivante :&nbsp;
              <MuiLink href={`mailto:${greffeRgpdEmail}`}>
                {greffeRgpdEmail}
              </MuiLink>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
