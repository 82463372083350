import React, { useContext, useState } from 'react'

import { Alert, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Switch, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import Headband from './components/Headband'
import { useLocation, useNavigate } from 'react-router-dom'
import useTheme from './themes/material'
import { ColorModeContext } from './context/ColorModeContext'
import MainContainer from './components/MainContainer'

export default function EditionUtilisateur () {
  const app = useContext(AppContext)
  const { colorMode, toggleColorMode } = useContext(ColorModeContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const { state } = useLocation()

  const user = { ...state }

  let defaultClientOption = null
  app.clientOptions.map(option => {
    if (option.id === user.client_code) {
      defaultClientOption = option
    }
    return option
  })

  const [clientOption, setClientOption] = useState(defaultClientOption)
  const [firstname, setFirstname] = useState(user.firstname || '')
  const [name, setName] = useState(user.name || '')
  const [email, setEmail] = useState(user.email || '')
  const [username, setUsername] = useState(user.username)
  const [admin, setAdmin] = useState(!!user.admin)
  const [active, setActive] = useState(!!user.active)
  const [confirmationDialogDeleteOpen, setConfirmationDialogDeleteOpen] = useState(false)

  const userCreate = () => {
    const createdUser = {
      client_code: clientOption?.id || '',
      firstname,
      name,
      email,
      admin,
      active
    }

    api.post('user', createdUser).then(response => {
      navigate(-1)
    }).catch(error => {
      app.snackbarError(error.message || 'Erreur lors de la création de l\'utilisateur')
    })
  }

  const userUpdate = () => {
    const updatedUser = {
      id: user.id,
      client_code: clientOption?.id || '',
      firstname,
      name,
      email,
      admin,
      active
    }

    api.post('user/' + updatedUser.id, updatedUser).then(response => {
      if (updatedUser.id === app.user.id) {
        app.init(response.data)
      }
      navigate(-1)
    }).catch(error => {
      app.snackbarError(error.message || 'Erreur lors de la modification de l\'utilisateur')
    })
  }

  const handleDelete = () => {
    if (user.id === app.user.id) {
      // Impossible de s'auto-supprimer
      return
    }

    api.get('user-delete/' + user.id).then(response => {
      navigate(-1)
    }).catch(error => {
      app.snackbarError(error.message || 'Erreur lors de la suppression de l\'utilisateur')
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    user.id ? userUpdate() : userCreate()
  }

  const sxTextfield = {
    display: 'block',
    margin: theme.spacing(2, 0)
  }

  const renderField = (field, setField, key, label, required, autoFocus) => (
    <TextField
      id={key}
      label={label}
      variant='filled'
      value={field}
      onChange={event => {
        setField(event.target.value)
      }}
      required={required}
      autoFocus={autoFocus}
      fullWidth
      sx={sxTextfield}
    />
  )

  const me = user.id && user.id === app.user.id

  return (
    <Box>
      <Headband alignLeft>
        <Typography variant='h1'>
          {user.id ? (firstname + ' ' + name) : 'Nouvel utilisateur'}
        </Typography>
        <Typography>
          Client {clientOption?.label || 'non renseigné'}
        </Typography>
      </Headband>
      <MainContainer>
        <form
          style={{
            maxWidth: '480px'
          }}
          onSubmit={event => handleSubmit(event)}
        >
          <Autocomplete
            disablePortal
            id='client'
            value={clientOption}
            disabled={me && !admin}
            onChange={(event, newOption) => {
              setClientOption(newOption)
            }}
            options={app.clientOptions}
            renderInput={params => (
              <TextField
                {...params}
                label='Client'
                variant='filled'
                required={!admin}
              />
            )}
          />
          {renderField(firstname, setFirstname, 'name', 'Prénom', true)}
          {renderField(name, setName, 'name', 'Nom', true)}
          {renderField(email, value => {
            setEmail(value)
            setUsername(!value ? '' : (app.greffe.code + '-' + value))
          }, 'email', 'Email', true)}
          <Alert severity="info">
            <small>Identifiant de connexion (la saisie du code greffe est facultative)</small>
            <Box>{username}</Box>
          </Alert>
          <FormGroup sx={{ margin: theme.spacing(4, 0) }}>
            {(!!app.user.master || !!app.user.admin) && (
              <FormControlLabel
                control={<Switch checked={admin} onChange={() => setAdmin(!admin)} />}
                label='Admin'
              />
            )}
            {!me && (
              <FormControlLabel
                control={<Switch checked={active} onChange={() => setActive(!active)} />}
                label='Actif'
              />
            )}
            {me && (
              <FormControlLabel
                control={<Switch checked={colorMode === 'dark'} onChange={toggleColorMode} />}
                label='Mode sombre'
              />
            )}
          </FormGroup>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            gap: theme.spacing(1),
            marginTop: theme.spacing(4)
          }}
          >
            <Button onClick={() => navigate(-1)}>Annuler</Button>
            <Button type='submit' variant='contained'>Enregistrer</Button>
            {user.id && user.id !== app.user.id && (
              <Button variant='contained' color='secondary' onClick={() => { setConfirmationDialogDeleteOpen(true) }}>
                Supprimer
              </Button>
            )}
          </Box>
        </form>
      </MainContainer>
      <Dialog
        maxWidth='xs'
        open={confirmationDialogDeleteOpen}
      >
        <DialogTitle>Attention</DialogTitle>
        <DialogContent>
          Vous êtes sur le point de supprimer l'utilisateur.
          <br />
          Cette action est irreversible.
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => { setConfirmationDialogDeleteOpen(false) }}>
            Annuler
          </Button>
          <Button onClick={handleDelete}>Supprimer l'utilisateur</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
