import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs'

import { FilledInput, FormControl, IconButton, InputAdornment, InputLabel, Typography, Select, MenuItem } from '@mui/material'
import DeleteIcon from '@mui/icons-material/CloseOutlined'
import Box from '@mui/material/Box'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import { dateTimeFr } from './lib/date'
import DatePicker from './components/DatePicker'
import Headband from './components/Headband'
import MyTableRow from './components/TableRow'
import ListItems from './components/ListItems'
import useTheme from './themes/material'
import PageContent from './components/PageContent'

export default function SuiviDeNavigation () {
  const app = useContext(AppContext)
  const theme = useTheme()
  const [cookies, setCookie] = useCookies()

  const dateMinDefault = cookies['suiviDeNavigation-dateMin'] ? dayjs(cookies['suiviDeNavigation-dateMin']) : dayjs(process.env.REACT_APP_DATE_NOW).startOf('month')
  const dateMaxDefault = cookies['suiviDeNavigation-dateMax'] ? dayjs(cookies['suiviDeNavigation-dateMax']) : dayjs(process.env.REACT_APP_DATE_NOW).endOf('month')

  const [items, setItems] = useState(null)
  const [page, setPage] = useState(0)
  const [dateMin, setDateMin] = useState(dateMinDefault)
  const [dateMax, setDateMax] = useState(dateMaxDefault)
  const [userSelected, setUserSelected] = useState('all')
  const [users, setUsers] = useState(null)
  const [url, setUrl] = useState('')

  const columns = [
    { code: 'count', label: '#', sort: 'count' },
    { code: 'userFullName', label: 'Utilisateur', sort: 'userFullName', sx: { textAlign: 'left' } },
    { code: 'url', label: 'Url', sort: 'url', sx: { textAlign: 'left' } },
    { code: 'createdDateTimeFr', label: 'Date', sort: 'created' }
  ]

  const getSysLogs = () => {
    setItems(null)
    setPage(0)

    api.get('suivi-de-navigation').then(response => {
      setItems(response.data)
    }).catch(error => {
      setItems([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des logs')
    })
  }

  const updateUsers = () => {
    if (items === null) {
      return
    }

    const usersArray = []
    const ids = {}

    items.map(item => {
      if (typeof ids[item.userId] === 'undefined') {
        ids[item.userId] = true
        usersArray.push({
          id: item.userId,
          fullName: item.userFullName
        })
      }
      return item
    })

    usersArray.sort((a, b) => {
      const aValue = typeof a.fullName === 'string' ? a.fullName.toLowerCase() : a.fullName
      const bValue = typeof b.fullName === 'string' ? b.fullName.toLowerCase() : b.fullName

      if (aValue > bValue) return 1
      if (aValue < bValue) return -1
      return 0
    })

    setUsers(usersArray)
  }

  const handleSetDateMin = (newDate) => {
    setDateMin(newDate)

    setCookie('suiviDeNavigation-dateMin', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSetDateMax = (newDate) => {
    setDateMax(newDate)

    setCookie('suiviDeNavigation-dateMax', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    getSysLogs()
  }

  useEffect(getSysLogs, [])

  useEffect(updateUsers, [items])

  const sxTextfield = {
    width: '240px'
  }

  const renderTableRow = (item) => {
    const row = { ...item }

    row.data = item

    row.createdDateTimeFr = row.created && row.created.date ? dateTimeFr(row.created.date) : ''

    return (
      <MyTableRow
        key={row.id}
        row={row}
        columns={columns}
      />
    )
  }

  const filteredItems = items && items.filter(item => {
    if (dayjs(item.created.date) < dateMin) {
      return false
    } else if (dateMax < dayjs(item.created.date).startOf('day')) {
      return false
    } else if (userSelected !== 'all' && userSelected !== item.userId) {
      return false
    } else if (url && (!item.url || item.url.toLowerCase().indexOf(url.toLowerCase()) === -1)) {
      return false
    }
    return item
  })

  return (
    <PageContent>
      <Headband alignLeft>
        <form style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }} onSubmit={event => handleSubmit(event)}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
            <Typography variant='h1' sx={{ display: 'inline-block', margin: '0' }}>Suivi de navigation</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: theme.spacing(4), alignItems: 'center' }}>
            <DatePicker
              label='Date de début'
              value={dateMin}
              onChange={handleSetDateMin}
            />
            <DatePicker
              label='Date de fin'
              value={dateMax}
              onChange={handleSetDateMax}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: theme.spacing(4), zzjustifyContent: 'space-between', alignItems: 'center' }}>
            <FormControl variant='filled'>
              <InputLabel id='user-select-label'>Utilisateur</InputLabel>
              <Select
                labelId='user-select-label'
                id='user-select'
                value={userSelected}
                onChange={event => setUserSelected(event.target.value)}
              >
                <MenuItem value='all'>Tous</MenuItem>
                {users && users.map(user => {
                  return <MenuItem key={user.id} value={user.id}>{user.fullName}</MenuItem>
                })}
              </Select>
            </FormControl>
            <FormControl sx={sxTextfield} variant='filled'>
              <InputLabel htmlFor='search'>Url</InputLabel>
              <FilledInput
                id='url'
                value={url}
                onChange={event => {
                  setUrl(event.target.value)
                }}
                endAdornment={url && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Bouton pour vider le champ'
                      onClick={() => {
                        setUrl('')
                        document.getElementById('url').focus()
                      }}
                      edge='end'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
          </Box>
        </form>
      </Headband>
      <ListItems
        name='suiviDeNavigation'
        items={filteredItems}
        page={page}
        setPage={setPage}
        renderTableRow={renderTableRow}
        columns={columns}
      />
    </PageContent>
  )
}
