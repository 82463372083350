import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/fr'
import { Paper } from '@mui/material'
import { useContext } from 'react'
import { ColorModeContext } from '../context/ColorModeContext'

export default function TextEditor (props) {
  const { colorMode } = useContext(ColorModeContext)

  return (
    <Paper elevation={0} className={colorMode}>
      <CKEditor
        {...props}
        style={{ border: 0 }}
        config={{
          language: 'fr',
          toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', 'blockQuote']
        }}
        editor={ClassicEditor}
      />
    </Paper>
  )
}
