import React from 'react'

import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import AlertNoResult from './AlertNoResult'
import TableSkeleton from './TableSkeleton'

export default function MyTable ({ items, rowsPerPage, page, renderTableRow, columns }) {
  if (items === null) {
    return <TableSkeleton columns={columns} />
  } else if (typeof items !== 'object' || items.length === 0) {
    return <AlertNoResult />
  }

  const filteredRows = page === false
    ? items
    : items.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )

  if (filteredRows.length === 0) {
    return <AlertNoResult />
  }

  let count = 0

  return (
    <Table sx={{ minWidth: 650, marginBottom: '52px' }} aria-label='Table principale'>
      <TableHead>
        <TableRow>
          {columns.map(column => (
            <TableCell
              key={column.code}
              component='th'
              align={column.align || 'left'}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredRows && filteredRows.map(row => {
          if (!row) {
            return null
          }

          row.count = row.noCount ? '' : (rowsPerPage * page + ++count)
          return renderTableRow(row)
        })}
      </TableBody>
    </Table>
  )
}
