import { Alert } from '@mui/material'
import { Box } from '@mui/system'
import useTheme from '../themes/material'

export default function AlertNoResult () {
  const theme = useTheme()

  const sxNoResult = {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacingResponsive,
    marginBottom: theme.spacingResponsive
  }

  return (
    <Box sx={sxNoResult}>
      <Alert severity='info'>
        Aucun r&eacute;sultat...
      </Alert>
    </Box>
  )
}
