import { useContext } from 'react'

import { Box, Paper } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

import useTheme from './../themes/material'
import { AppContext } from './../context/AppContext'
import { ColorModeContext } from '../context/ColorModeContext'

export default function Headband ({ children, sx, height, alignLeft }) {
  const app = useContext(AppContext)
  const { colorMode } = useContext(ColorModeContext)
  const theme = useTheme('dark')

  const sxAbsolute = { position: 'absolute', height: '100%', width: '100%', top: 0 }
  const sxFlex = {
    display: 'flex',
    position: 'relative',
    minHeight: height || '120px',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: {
      xs: theme.spacing(2),
      sm: theme.spacing(3),
      md: theme.spacing(4),
      lg: theme.spacing(4),
      xl: theme.spacing(4)
    },
    paddingBottom: {
      xs: theme.spacing(2),
      sm: theme.spacing(3),
      md: theme.spacing(4),
      lg: theme.spacing(4),
      xl: theme.spacing(4)
    },
    backgroundColor: 'transparent'
  }
  if (alignLeft) {
    sxFlex.paddingLeft = {
      xs: theme.spacing(10),
      sm: theme.spacing(10),
      md: theme.spacing(12),
      lg: theme.spacing(6),
      xl: theme.spacing(8)
    }
    sxFlex.paddingRight = theme.spacingResponsive
  } else {
    sxFlex.alignItems = 'center'
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          ...sx,
          position: 'relative',
          color: colorMode === 'dark' ? 'inherit' : theme.palette.primary.main,
          '& .MuiInputBase-root': {
            width: '200px'
          }
        }}
      >
        <Box sx={{
          ...sxAbsolute,
          backgroundColor: theme.palette.primary.real
        }}
        />
        <Box sx={{
          ...sxAbsolute,
          backgroundColor: colorMode === 'dark' ? '#000000cc' : 'transparent'
        }}
        />
        <Box sx={{
          ...sxAbsolute,
          backgroundImage: `url(/image/photo-greffe-${app.greffe.code}.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          opacity: 0.1
        }}
        />
        <Paper elevation={0} sx={{ ...sxFlex }}>
          {children}
        </Paper>
      </Box>
    </ThemeProvider>
  )
}
