import Headband from './Headband'
import WelcomeTitle from './WelcomeTitle'

const sxDefault = {
  minHeight: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  display: { xs: 'none', md: 'block' },
  flexGrow: 1
}

export default function WelcomeAside ({ sx }) {
  return (
    <Headband
      height='100vh'
      sx={{ ...sxDefault, sx }}
    >
      <WelcomeTitle />
    </Headband>
  )
}
