import { createContext, useState } from 'react'
import { useSnackbar } from 'notistack'
import { AppBar, Button, Dialog, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import useTheme from '../themes/material'

export const AppContext = createContext()

const AppProvider = ({ children }) => {
  const theme = useTheme()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [ready, setReady] = useState(false)
  const [greffe, setGreffe] = useState(null)
  const [user, setUser] = useState(null)
  const [userReplay, setUserReplay] = useState(null)
  const [passwordExpired, setPasswordExpired] = useState(false)
  const [client, setClient] = useState(null)
  const [colorMode, setColorMode] = useState('light')
  const [clientOptions, setClientOptions] = useState(null)
  const [auteurOptions, setAuteurOptions] = useState(null)
  const [popinOpen, setPopinOpen] = useState(false)
  const [popinTitle, setPopinTitle] = useState('')
  const [popinSrc, setPopinSrc] = useState('')
  const [popinContent, setPopinContent] = useState(null)
  const [popinFullScreen, setPopinFullScreen] = useState(true)

  const login = (user, callback) => {
    setUser(user)
    if (user.master) {
      setUserReplay(user)
    }
    setTimeout(callback, 100)
  }

  const handlePopinClose = () => {
    setPopinOpen(false)
  }

  const value = {
    ready,
    greffe,
    user,
    userReplay,
    passwordExpired,
    client,
    colorMode,
    clientOptions,
    auteurOptions,
    toggleColorMode: () => {
      setColorMode(colorMode === 'dark' ? 'light' : 'dark')
    },
    init: (data, callback) => {
      setReady(true)
      window.document.title = 'GTC ' + (data?.greffe?.city || '') + ' : Espace client'
      setGreffe(data.greffe || null)
      setClientOptions(data.clientOptions || null)
      setAuteurOptions(data.auteurOptions || null)
      setClient(data.client || null)
      setPasswordExpired(data.passwordExpired || false)
      if (data.user) {
        login(data.user, callback)
        return
      }
      setTimeout(callback, 100)
    },
    login,
    logout: (callback) => {
      setUser(null)
      setUserReplay(null)
      setPasswordExpired(false)
      setClient(null)
      setTimeout(callback, 100)
    },
    setClient,
    snackbar: enqueueSnackbar,
    closeSnackbar,
    snackbarError: (message) => (
      enqueueSnackbar(message, { variant: 'error' })
    ),
    snackbarWarning: (message) => (
      enqueueSnackbar(message, { variant: 'warning' })
    ),
    snackbarInfo: (message) => (
      enqueueSnackbar(message, { variant: 'info' })
    ),
    snackbarSuccess: (message) => (
      enqueueSnackbar(message, { variant: 'success' })
    ),
    openDialog: (content, title, fullScreen) => {
      setPopinContent(content)
      setPopinSrc('')
      setPopinTitle(title || '')
      setPopinFullScreen(!!fullScreen)
      setPopinOpen(true)
    },
    openDialogEmbed: (src, title) => {
      setPopinSrc(src)
      setPopinContent(null)
      setPopinTitle(title || '')
      setPopinFullScreen(true)
      setPopinOpen(true)
    }
  }

  return (
    <AppContext.Provider value={value}>
      {children}
      <Dialog
        fullScreen={popinFullScreen}
        open={popinOpen}
        onClose={handlePopinClose}
      >
        {!!popinTitle && (
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                {popinTitle || ''}
              </Typography>
              <Button
                endIcon={<CloseIcon />}
                onClick={handlePopinClose}
                color='inherit'
                edge='end'
                aria-label='close'
                sx={{ marginRight: theme.spacing(-1) }}
              >
                Fermer
              </Button>
            </Toolbar>
          </AppBar>
        )}
        {!!popinSrc && (
          <embed
            src={popinSrc}
            type='application/pdf'
            style={{ height: 'calc(100vh - 64px)' }}
          />
        )}
        {!popinSrc && popinContent}
      </Dialog>
    </AppContext.Provider>
  )
}

export default AppProvider
