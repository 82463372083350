import { useContext } from 'react'
import { Alert, Button, Typography } from '@mui/material'
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined'
import useTheme from '../themes/material'
import { AppContext } from '../context/AppContext'
import { euro } from '../lib/format'
import { getApprovisionnementUrl } from '../lib/outilsWeb'

export default function BoxClientAvecProvision () {
  const theme = useTheme()
  const app = useContext(AppContext)
  const client = app.client

  return (
    <Alert
      variant='filled'
      severity={client.solde > 500 ? 'success' : (client.solde > 0 ? 'warning' : 'error')}
      icon={false}
      sx={{
        padding: theme.spacing(4),
        textAlign: 'center',
        display: 'inline-block'
      }}
    >
      <SavingsOutlinedIcon fontSize='large' />
      <Typography>Client avec provision</Typography>
      <Typography variant='h5'>Solde : {euro(client.solde)}</Typography>
      {app.greffe.gestion_approvisionnement && (
        <Button
          variant='outlined'
          color='inherit'
          component='a'
          target='_blank'
          sx={{ marginTop: theme.spacing(3) }}
          href={getApprovisionnementUrl(client.code, app.user.email)}
          onClick={event => {
            event.preventDefault()
            window.open(event.currentTarget.href)
            return false
          }}
        >
          Approvisionner le compte
        </Button>
      )}
    </Alert>
  )
}
