import { Button } from '@mui/material'
import Loader from './Loader'

export default function LoadingButton (props) {
  const endIcon = props.endIcon ? (props.loading ? <Loader size='xs' /> : props.endIcon) : null

  const buttonProps = {
    ...props,
    endIcon,
    disabled: props.loading
  }

  delete buttonProps.loading
  delete buttonProps.children

  return (
    <Button
      {...buttonProps}
    >
      {props.children}
    </Button>
  )
}
