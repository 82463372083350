import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Typography, FormControl, InputLabel, FilledInput, IconButton } from '@mui/material'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import IconPersonOutlined from '@mui/icons-material/PersonOutline'
import IconVisibility from '@mui/icons-material/Visibility'
import IconVisibilityOff from '@mui/icons-material/VisibilityOff'
import Alert from '@mui/material/Alert'

import api from './lib/api'
import Logo from './components/Logo'
import Link from './components/Link'
import WelcomeAside from './components/WelcomeAside'
import useTheme from './themes/material'
import { passwordValidator } from './lib/validator'
import MotDePasseInfo from './components/MotDePasseInfo'
import Captcha from './components/Captcha'

export default function CreerUnCompte () {
  const theme = useTheme()
  const { email: emailParam } = useParams()
  const recaptcha = useRef()

  const [email, setEmail] = useState(emailParam || process.env.REACT_APP_EMAIL || '')
  const [firstname, setFirstname] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordBis, setPasswordBis] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [isValidBis, setIsValidBis] = useState(password === passwordBis)
  const [alertText, setAlertText] = useState('')
  const [alertSeverity, setAlertSeverity] = useState('info')
  const [showPassword, setShowPassword] = useState(false)
  const [captchaValue, setCaptchaValue] = useState('')
  const [uniqId, setUniqId] = useState('')
  const [canSubmit, setCanSubmit] = useState(false)

  useEffect(() => {
    setCanSubmit(
      !!email
      && !!firstname
      && !!name
      && !!isValid
      && !!isValidBis
      && !!captchaValue
      && alertSeverity === 'info'
    )
  }, [email, firstname, name, isValid, isValidBis, captchaValue, alertSeverity])

  const handleChangePassword = event => {
    setPassword(event.target.value)
    setAlertText('')
    setAlertSeverity('info')

    setIsValid(passwordValidator(event.target.value))
    setIsValidBis(event.target.value === passwordBis)
  }

  const handleChangePasswordBis = event => {
    setPasswordBis(event.target.value)
    setAlertText('')
    setAlertSeverity('info')

    setIsValidBis(password === event.target.value)
  }

  const handleChangeCaptcha = (event) => {
    setAlertText('')
    setAlertSeverity('info')
    setCaptchaValue(event.currentTarget.value)
  }

  const handleSubmit = async (event) => {
    setCanSubmit(false)
    event.preventDefault()

    api.post('user-create-demand', {
      email, firstname, name, password, passwordBis, captchaValue
    }).then(() => {
      setCaptchaValue('')
      setUniqId(Math.floor(Math.random() * 1000000))
      setAlertText('Vous allez recevoir un email contenant un lien pour finaliser la création de votre compte.')
      setAlertSeverity('success')
    }).catch(error => {
      setCaptchaValue('')
      setUniqId(Math.floor(Math.random() * 1000000))
      setAlertText(error.message || 'Erreur lors de la demande de création de compte')
      setAlertSeverity('error')
    })
  }

  const sx = { width: '100%', margin: '.5rem 0' }
  const sxButton = { minWidth: '200px' }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <WelcomeAside />
      <Box sx={{ padding: '2rem 6vw', maxHeight: '100vh', overflow: 'hidden', overflowY: 'auto' }}>
        <Box sx={{ width: '432px', padding: '0 16px', margin: 'auto' }}>
          <Logo style={{ margin: '0 auto 3rem' }} />
          <Typography variant='subtitle' sx={{ ...sx, textAlign: 'center' }}>
            Créer un compte
          </Typography>
          <form onSubmit={event => handleSubmit(event)}>
            <TextField
              id='email'
              label='Identifiant (Email)'
              variant='filled'
              value={email}
              required
              sx={sx}
              autoFocus={!email}
              onChange={event => {
                setEmail(event.target.value)
                setAlertText('')
                setAlertSeverity('info')
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconPersonOutlined />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              id='firstname'
              label='Prénom'
              variant='filled'
              value={firstname}
              required
              sx={sx}
              autoFocus={!!email}
              onChange={event => {
                setFirstname(event.target.value)
              }}
            />
            <TextField
              id='name'
              label='Nom'
              variant='filled'
              value={name}
              required
              sx={sx}
              onChange={event => {
                setName(event.target.value)
              }}
            />
            <FormControl variant='filled' required sx={sx}>
              <InputLabel htmlFor='password'>Mot de passe</InputLabel>
              <FilledInput
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handleChangePassword}
                error={!isValid}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <IconVisibilityOff /> : <IconVisibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl variant='filled' required sx={sx}>
              <InputLabel htmlFor='password'>Confirmation du mot de passe</InputLabel>
              <FilledInput
                type={showPassword ? 'text' : 'password'}
                value={passwordBis}
                onChange={handleChangePasswordBis}
                error={!isValidBis}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <IconVisibilityOff /> : <IconVisibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <MotDePasseInfo sx={sx} />
            <Captcha uniqId={uniqId} value={captchaValue} onChange={handleChangeCaptcha} />
            {alertText && (
              <Alert severity={alertSeverity} sx={sx}>{alertText}</Alert>
            )}
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '1.5rem',
              gap: theme.spacing(2)
            }}
            >
              {alertSeverity !== 'success' && (
                <>
                  <Link component={Button} to='/connexion' disabled={alertSeverity !== 'info'}>Annuler</Link>
                  <Button variant='contained' sx={sxButton} size='large' type='submit' disabled={!canSubmit}>
                    Valider
                  </Button>
                </>
              )}
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  )
}
