import { Alert } from '@mui/material'

export default function MotDePasseInfo ({ sx }) {
  return (
    <Alert severity='info' sx={sx}>
      Le mot de passe doit respecter les règles suivantes :
      <ul style={{ margin: 0, padding: '.5rem 1rem .25rem' }}>
        <li>12 caractères minimum</li>
        <li>1 lettre majuscule minimum</li>
        <li>1 lettre minuscule minimum</li>
        <li>1 chiffre minimum</li>
        <li>1 caractère spécial minimum #{}()[]+-*_$?!</li>
      </ul>
    </Alert>
  )
}
